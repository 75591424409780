import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar1 from "../../components/sidebar1/Sidebar1";
import Navbar from "../../components/navbar/Navbar";
import AuthContext from "../../contexts/AuthContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import "./admin.css";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Button from "react-bootstrap/Button";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import axios from "axios";
import { CircularProgress } from "@mui/material";

// using for data-grid
function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  ...customCheckbox(theme),
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const PAGE_SIZE = 20;

const onButtonClick = (e, row) => {
  // e.stopPropagation();
  //do whatever you want with the row
};

const ValidateIPaddress = (ipaddress) => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true;
  } else {
    return false;
  }
};

// end of using for data-grid

const Admin = () => {
  // get auth-token
  let [token, setToken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  // contexts
  const authContext = useContext(AuthContext);
  const { user, authTokens } = authContext;

  // states
  const [adminProxies, setAdminProxies] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const [show, setShow] = useState(false);
  const [isShowedDetailModal, setIsShowedDetailModal] = useState(false);
  const [url, setUrl] = useState("");
  const [ports, setPorts] = useState(0);
  const [status, setStatus] = useState(true);
  const [packageName, setPackageName] = useState("vip");
  const [authType, setAuthType] = useState("ip");
  const [proxyType, setProxyType] = useState("http");
  const [proxyVersion, setProxyVersion] = useState("ip6");
  const [listAdminProxiesAgainflag, setListAdminProxiesAgainflag] =
    useState(false);
  const [loadingAddProxy, setLoadingAddProxy] = useState(false);

  // Using for update a proxy
  const [proxyObject, setProxyObject] = useState();
  const [urlUpdate, setUrlUpdate] = useState("");
  const [portsUpdate, setPortsUpdate] = useState(0);
  const [statusUpdate, setStatusUpdate] = useState(undefined);
  const [liveUpdate, setLiveUpdate] = useState(undefined);
  const [authTypeUpdate, setAuthTypeUpdate] = useState("");
  const [proxyTypeUpdate, setProxyTypeUpdate] = useState("");
  const [proxyVersionUpdate, setProxyVersionUpdate] = useState("");
  const [packageNameUpdate, setPackageNameUpdate] = useState("");

  // using for list line proxies
  const [isShowedLineProxiesList, setIsShowedLineProxiesList] = useState(false);

  const handleClose = () => {
    setShow(false);
    // set states
    setAuthType("ip");
    setPorts(0);
  };
  const handleShow = () => setShow(true);

  const handleCloseDetailModal = () => {
    setIsShowedDetailModal(false);
  };

  const handleShowDetailModal = () => {
    setIsShowedDetailModal(true);
  };

  const handleCloseLineProxiesListModal = () => {
    setIsShowedLineProxiesList(false);
  };

  const handleShowLineProxiesListModal = () => {
    setIsShowedLineProxiesList(true);
  };

  let ListAdminProxies = async (token) => {
    const headers = { Authorization: "Bearer " + token };

    axios
      .get("/api/v1/admin/proxy-managers", { headers })
      .then((res) => {
        setAdminProxies(res.data.data.items);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddProxy = (
    packageName,
    url,
    ports,
    status,
    proxyType,
    proxyVersion
  ) => {
    if (ports <= 0) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your ports input cannot be empty and it must be larger than 0!",
      });
    }

    setLoadingAddProxy(true);
    let data = {
      url: url,
      ports: Number(ports),
      status: status,
      package_name: packageName,
      proxy_type: proxyType,
      proxy_version: proxyVersion,
    };
    axios
      .post(`/api/v1/admin/proxy-managers`, data)
      .then((res) => {
        if (res.status === 200) {
          setListAdminProxiesAgainflag((prevState) => !prevState);
          Swal.fire("Created!", `A new proxy has been created.`, "success");
        }
        setLoadingAddProxy(false);
        setShow(false);
      })
      .catch((err) => {
        console.error("Something went wrong!", err);
        setLoadingAddProxy(false);
        setShow(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Message: " + `${err?.response?.data?.message}`,
        });
      });
  };

  const handleUpdateProxy = (
    urlUpdate,
    portsUpdate,
    statusUpdate,
    liveUpdate,
    proxyTypeUpdate,
    proxyVersionUpdate,
    packageNameUpdate,
    proxyId
  ) => {
    if (!portsUpdate || portsUpdate <= 0) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your ports input cannot be empty and must be greater than 0!",
      });
    }

    let updated_data = {
      url: urlUpdate,
      ports: Number(portsUpdate),
      status: statusUpdate,
      live: liveUpdate,
      proxy_type: proxyTypeUpdate,
      proxy_version: proxyVersionUpdate,
      package_name: packageNameUpdate,
    };

    axios
      .put(`/api/v1/admin/proxy-managers/${proxyId}`, updated_data)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire(
            "Updated!",
            `The proxy with id: ${proxyId} has been Updated!`,
            "success"
          );
          handleCloseDetailModal();
          setListAdminProxiesAgainflag((prevState) => !prevState);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const LoadDetailProxy = (proxyId) => {
    axios({
      method: "GET",
      url: `/api/v1/admin/proxy-managers/${proxyId}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setProxyObject(res.data.data);

          setUrlUpdate(res.data.data.url);
          setPortsUpdate(res.data.data.ports);
          setStatusUpdate(res.data.data.status);
          setLiveUpdate(res.data.data.live);
          // setAuthTypeUpdate(res.data.data.auth_type);
          setProxyTypeUpdate(res.data.data.proxy_type);
          setProxyVersionUpdate(res.data.data.proxy_version);
          setPackageNameUpdate(res.data.data.package_name);
          setIsShowedDetailModal(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const GenerateProxy = (proxyId) => {
    Swal.fire({
      title: "Are you sure you want to generate for this proxy?",
      text: `id: ${proxyId}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#2dce89",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, generate it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/api/v1/admin/proxy-generations/${proxyId}/generate`, {})
          .then((res) => {
            if (res.status === 200) {
              Swal.fire(
                "Generated!",
                `The proxy with id: ${proxyId} has been generated.`,
                "success"
              );
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Message: " + `${err?.response?.data?.message}`,
            });
          });
      }
    });
  };

  useLayoutEffect(() => {
    if (token) {
      ListAdminProxies(token);
    }
  }, [listAdminProxiesAgainflag]);

  useEffect(() => {
    adminProxies.forEach((item, index) => {
      item.serial = index + 1;
      item.actions = { renderCell: () => <button>Add</button> };
    });

    // set Status and live of admin proxies all to True
    adminProxies.map((item, index) => {
      item.status = true;
      item.live = true;
    });
  }, [adminProxies]);

  const DeleteProxyLine = (proxyId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "DELETE",
          url: `/api/v1/admin/proxy-managers/${proxyId}`,
        })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire(
                "Deleted!",
                `This proxy with id: ${proxyId} has been deleted!`,
                "success"
              );

              setListAdminProxiesAgainflag((prevState) => !prevState);
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Message: " + `${err?.response?.data?.message}`,
            });
          });
      }
    });
  };

  const columns = useMemo(() => {
    const result = [
      // { field: "serial", headerName: "ID", width: 60 },
      // { field: "id", headerName: "ID", width: 300 },
      { field: "url", headerName: "URL", width: 250 },
      { field: "package_name", headerName: "PACKAGE NAME", width: 200 },
      { field: "ports", headerName: "PORTS", width: 240 },
      { field: "proxy_type", headerName: "TYPE", width: 240 },
      { field: "proxy_version", headerName: "VERSION", width: 240 },
      {
        field: "status",
        headerName: "STATUS",
        width: 90,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center">
              {params.value === true && (
                <span className="c-pill c-pill--success">True</span>
              )}
              {params.value === false && (
                <span className="c-pill c-pill--danger">False</span>
              )}
            </div>
          );
        },
      },
      {
        field: "live",
        headerName: "LIVE",
        width: 90,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center">
              {params.value === true && (
                <span className="c-pill c-pill--success">Live</span>
              )}
              {params.value === false && (
                <span className="c-pill c-pill--success">false</span>
              )}
            </div>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        renderCell: (params) => {
          return (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div class="btn-group">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover
                      id="popover-positioned-bottom"
                      title="Proxy list action"
                    >
                      <div className="px-2 py-2" role="button">
                        <div
                          className="d-flex align-items-center px-1 py-1 rounded popover-content"
                          onClick={() => LoadDetailProxy(params.row.id)}
                        >
                          <DisplaySettingsIcon className="text-secondary" />

                          <span className="ms-4 fw-bold text-secondary">
                            Detail & Update
                          </span>
                        </div>
                        <div
                          className="d-flex align-items-center px-1 py-1 rounded popover-content"
                          onClick={() => GenerateProxy(params.row.id)}
                        >
                          <AddTaskIcon className="text-secondary" />

                          <span className="ms-4 fw-bold text-secondary">
                            Generate
                          </span>
                        </div>
                        <Link
                          className="d-flex align-items-center px-1 py-1 rounded popover-content"
                          to={`line-proxies-list/${params.row.id}`}
                          target="_blank"
                        >
                          <ReceiptLongIcon className="text-secondary" />

                          <span className="ms-4 fw-bold text-secondary">
                            List Proxies
                          </span>
                        </Link>
                        <div
                          className="d-flex align-items-center px-1 py-1 rounded popover-content"
                          onClick={() => DeleteProxyLine(params.row.id)}
                        >
                          <DeleteForeverIcon
                            className="text-secondary"
                            style={{ fontSize: "1.7rem" }}
                          />

                          <span className="ms-4 fw-bold text-secondary">
                            Remove
                          </span>
                        </div>
                      </div>
                    </Popover>
                  }
                >
                  <button
                    type="button"
                    className="rounded-circle border bg-light px-1 py-1"
                  >
                    <MoreHorizIcon style={{ display: "block" }} />
                  </button>
                </OverlayTrigger>
                {/* <button
                  type="button"
                  class="btn btn-sm btn-light dropdown-toggle my-0 my-2 px-4"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <MoreHorizIcon />
                </button> */}

                {/* <ul class="dropdown-menu">
                  <li>
                    <div class="dropdown-item d-flex justify-content-center">
                      <Button
                        className="my-0 px-0 px-3 btn-sm shadow"
                        variant="outline-info"
                        value={params.row.id}
                        onClick={() => LoadDetailProxy(params.row.id)}>
                        Detail
                      </Button>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-item d-flex justify-content-center">
                      <Button
                        className="my-0 px-0 px-3 btn-sm shadow"
                        variant="outline-success"
                        onClick={() => GenerateProxy(params.row.id)}>
                        Generate
                      </Button>{" "}
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-item d-flex justify-content-center">
                      <Link
                        className="text-primary"
                        to={`line-proxies-list/${params.row.id}`}
                        target="_blank">
                        <Button
                          className="my-0 px-0 px-3 btn-sm shadow"
                          variant="outline-primary">
                          List Proxies
                        </Button>
                      </Link>
                    </div>
                  </li>

                  <li>
                    <div class="dropdown-item d-flex justify-content-center">
                      <Button
                        className="my-0 px-0 px-3 btn-sm shadow"
                        variant="outline-danger"
                        value={params.row.id}
                        onClick={() => DeleteProxyLine(params.row.id)}>
                        Remove
                      </Button>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
          );
        },
      },
    ];
    return result;
  }, []);

  if (!user) {
    // not authenticated
    return <Navigate to={"/login"} replace="true" />;
  } else {
    if (user?.group?.role === "user") {
      return <Navigate to={"/dashboard"} replace="true" />;
    } else if (user?.group?.role === "reseller") {
      return <Navigate to="/reseller/package-management" />;
    } else if (
      user?.group?.role === "admin" ||
      user?.group?.role === "superadmin"
    ) {
      return (
        <React.Fragment>
          <Sidebar1 />
          <main className="main-content position-relative border-radius-lg ">
            <Navbar />

            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-12">
                  <div className="card mb-4">
                    <div className="card-header text-uppercase pb-0">
                      <h6>Proxy list</h6>

                      {/* buttons */}
                      <div className="border-top border-bottom d-flex align-items-center justify-content-between">
                        <div>
                          <span className="text-capitalize">Total:</span>
                          <span className="ms-2 text-bold">
                            {adminProxies?.length}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={handleShow}
                          className=" my-2 border-0 badge badge-sm bg-gradient-success d-flex align-items-center"
                        >
                          <ControlPointIcon className="fs-4" />{" "}
                          <span className="ms-1">Add</span>
                        </button>
                      </div>
                    </div>

                    <div className="card-body px-0 pt-0 pb-2">
                      <div
                        className="border-top"
                        style={{ height: 860, width: "100%" }}
                      >
                        <StyledDataGrid
                          // test
                          sx={{
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                              {
                                display: "none",
                              },
                            "& .css-i4bv87-MuiSvgIcon-root": {
                              display: "none",
                            },
                          }}
                          disableRowSelectionOnClick
                          // getRowHeight={() => "auto"}
                          rowHeight={46}
                          rows={adminProxies}
                          columns={columns}
                          // test
                          checkboxSelection
                          paginationModel={paginationModel}
                          onPaginationModelChange={setPaginationModel}
                          pageSizeOptions={[PAGE_SIZE]}
                          slots={{
                            pagination: CustomPagination,
                          }}
                          {...adminProxies}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          {/* Add button modal */}
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add Proxy {loadingAddProxy && <CircularProgress size="1rem" />}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>PACKAGE NAME</Form.Label>
                  <Form.Select
                    value={packageName}
                    onChange={(e) => setPackageName(e.target.value)}
                  >
                    <option value="vip">vip</option>
                    <option value="standard">standard</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                    placeholder="Enter url"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>PORTS</Form.Label>
                  <Form.Control
                    type="number"
                    value={ports}
                    onChange={(e) => setPorts(e.target.value)}
                    placeholder="Enter package days"
                  />
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1">
                  <Form.Label>AUTH TYPE</Form.Label>
                  <Form.Select
                    value={authType}
                    onChange={(e) => setAuthType(e.target.value)}>
                    <option value="ip">ip</option>
                    <option value="user">user+pass</option>
                  </Form.Select>
                </Form.Group> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>PROXY TYPE</Form.Label>
                  <Form.Select
                    value={proxyType}
                    onChange={(e) => setProxyType(e.target.value)}
                  >
                    <option value="http">http</option>
                    <option value="sock">sock</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>PROXY VERSION</Form.Label>
                  <Form.Select
                    disabled
                    value={proxyType}
                    onChange={(e) => setProxyVersion(e.target.value)}
                  >
                    <option value="ip6">ip6</option>
                    <option value="ip4">ip4</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>STATUS</Form.Label>

                  <Form.Select
                    disabled
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                  </Form.Select>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>

              {!loadingAddProxy ? (
                <Button
                  variant="success"
                  onClick={() =>
                    handleAddProxy(
                      packageName,
                      url,
                      ports,
                      status,
                      proxyType,
                      proxyVersion
                    )
                  }
                >
                  Add Proxy
                </Button>
              ) : (
                <Button disabled variant="success">
                  <CircularProgress size="1.1rem" className="mx-3" />
                </Button>
              )}
            </Modal.Footer>
          </Modal>

          {/* Proxy Detail and Update modal*/}
          <Modal
            show={isShowedDetailModal}
            onHide={handleCloseDetailModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Proxy Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="ID">
                    <Form.Control
                      disabled
                      type="text"
                      value={proxyObject?.id ? proxyObject.id : ""}
                      autoFocus
                    />
                  </Form.FloatingLabel>
                </Form.Group> */}
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="PACKAGE NAME">
                    <Form.Control
                      disabled
                      type="text"
                      // onChange={(e) => setUrlUpdate(e.target.value)}
                      value={
                        proxyObject?.package_name
                          ? proxyObject.package_name
                          : ""
                      }
                    />
                  </Form.FloatingLabel>
                </Form.Group>
                {/* <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="PROXY NUMBER">
                    <Form.Control
                      disabled
                      type="text"
                      // onChange={(e) => setUrlUpdate(e.target.value)}
                      value={
                        proxyObject?.proxy_number
                          ? proxyObject.package_name
                          : ""
                      }
                    />
                  </Form.FloatingLabel>
                </Form.Group> */}
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="URL">
                    <Form.Control
                      disabled
                      type="text"
                      // onChange={(e) => setUrlUpdate(e.target.value)}
                      value={urlUpdate && urlUpdate}
                    />
                  </Form.FloatingLabel>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="PORTS">
                    <Form.Control
                      type="number"
                      onChange={(e) => setPortsUpdate(e.target.value)}
                      value={portsUpdate && portsUpdate}
                    />
                  </Form.FloatingLabel>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="STATUS">
                    <Form.Select
                      disabled
                      value={statusUpdate && statusUpdate}
                      onChange={(e) => setStatusUpdate(e.target.value)}
                    >
                      <option value={Boolean(true)}>true</option>
                      <option value={Boolean(false)}>false</option>
                    </Form.Select>
                  </Form.FloatingLabel>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="LIVE">
                    <Form.Select
                      disabled
                      value={liveUpdate && liveUpdate}
                      onChange={(e) => setLiveUpdate(e.target.value)}
                    >
                      <option value={Boolean(true)}>true</option>
                      <option value={Boolean(false)}>false</option>
                    </Form.Select>
                  </Form.FloatingLabel>
                </Form.Group>
                {/* <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1">
                  <Form.FloatingLabel label="AUTH TYPE">
                    <Form.Select
                      disabled
                      onChange={(e) => setAuthTypeUpdate(e.target.value)}
                      value={authTypeUpdate}>
                      <option value={"ip"}>ip</option>
                      <option value={"user"}>user+pass</option>
                    </Form.Select>
                  </Form.FloatingLabel>
                </Form.Group> */}
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="PROXY TYPE">
                    <Form.Select
                      disabled
                      // onChange={(e) => setProxyTypeUpdate(e.target.value)}
                      value={proxyObject?.proxy_type && proxyObject.proxy_type}
                    >
                      <option value={"http"}>http</option>
                      <option value={"sock"}>sock</option>
                    </Form.Select>
                  </Form.FloatingLabel>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="IP4">
                    <Form.Control
                      disabled
                      type="text"
                      value={proxyObject?.ip4 ? proxyObject.ip4 : ""}
                      autoFocus
                    />
                  </Form.FloatingLabel>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="IP6">
                    <Form.Control
                      disabled
                      type="text"
                      value={proxyObject?.ip6 ? proxyObject.ip6 : ""}
                    />
                  </Form.FloatingLabel>
                </Form.Group>
                {/* <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="INTERFACE">
                    <Form.Control
                      disabled
                      type="text"
                      value={
                        proxyObject?.interface ? proxyObject.interface : ""
                      }
                    />
                  </Form.FloatingLabel>
                </Form.Group> */}
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.FloatingLabel label="CREATEAD AT">
                    <Form.Control
                      disabled
                      type="text"
                      value={
                        proxyObject?.created_at ? proxyObject.created_at : ""
                      }
                    />
                  </Form.FloatingLabel>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.FloatingLabel label="UPDATED AT">
                    <Form.Control
                      disabled
                      type="text"
                      value={
                        proxyObject?.updated_at ? proxyObject.updated_at : ""
                      }
                    />
                  </Form.FloatingLabel>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDetailModal}>
                Close
              </Button>
              <Button
                variant="success"
                onClick={() =>
                  handleUpdateProxy(
                    urlUpdate,
                    portsUpdate,
                    statusUpdate,
                    liveUpdate,
                    proxyTypeUpdate,
                    proxyVersionUpdate,
                    packageNameUpdate,
                    proxyObject.id
                  )
                }
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/* List of Line Proxies */}
          <Modal
            show={isShowedLineProxiesList}
            onHide={handleCloseLineProxiesListModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Proxies List</Modal.Title>
            </Modal.Header>
            <Modal.Body>{/* test */}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseLineProxiesListModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  }
};

export default Admin;
