import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import { useContext } from "react";
import logo1 from "../../assets/img/proxy-10.png"; //boot24hproxy
import logo2 from "../../assets/img/server.png"; //mkproxy

const Sidebar1 = () => {
  // contexts
  const authContext = useContext(AuthContext);
  const { user, domain } = authContext;

  const [adminToggle, setAdminToggle] = useState(true);
  const [userPackagesToggle, setUserPackagesToggle] = useState(false);
  const [userManagementToggle, setUserManagementToggle] = useState(false);
  const [dashboardToggle, setDashboardToggle] = useState(false);

  const [supportToggle, setSupportToggle] = useState(false);

  const [packagePurchaseToggle, setPackagePurchaseToggle] = useState(false);
  // const [userPackageManagementToggle, setUserPackageManagementToggle] =
  //   useState(false);
  const [packageManagementToggle, setPackageManagementToggle] = useState(false);
  const [proxyManagementToggle, setProxyManagementToggle] = useState(false);
  const [customerSupportToggle, setCustomerSupportToggle] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/admin/user-packages") {
      setUserPackagesToggle(true);
      setAdminToggle(false);
    } else if (window.location.pathname === "/admin/proxy-management") {
      setAdminToggle(true);
      setUserPackagesToggle(false);
    } else if (window.location.pathname === "/user/package-purchase") {
      setPackagePurchaseToggle(true);
      setPackageManagementToggle(false);
      setProxyManagementToggle(false);
      setCustomerSupportToggle(false);
    } else if (window.location.pathname === "/user/package-management") {
      setPackageManagementToggle(true);
      setPackagePurchaseToggle(false);
      setProxyManagementToggle(false);
      setCustomerSupportToggle(false);
    } else if (window.location.pathname === "/user/proxy-management") {
      setProxyManagementToggle(true);
      setPackageManagementToggle(false);
      setPackagePurchaseToggle(false);
      setCustomerSupportToggle(false);
    } else if (window.location.pathname === "/user/support") {
      setCustomerSupportToggle(true);
      setProxyManagementToggle(false);
      setPackageManagementToggle(false);
      setPackagePurchaseToggle(false);
    } else if (window.location.pathname === "/reseller/user-management") {
      setUserManagementToggle(true);
      setPackageManagementToggle(false);
    } else if (window.location.pathname === "/reseller/package-management") {
      setPackageManagementToggle(true);
      setUserManagementToggle(false);
    } else {
      setUserPackagesToggle(false);
      setAdminToggle(false);
    }
  }, []);

  return (
    <>
      {/* <!-- sidebar --> */}
      <aside
        className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 "
        id="sidenav-main"
      >
        {domain == "boost24h" ? (
          <>
            {/* BOOST24H */}
            <div className="sidenav-header">
              <i
                className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                aria-hidden="true"
                id="iconSidenav"
              ></i>
              <Link className="text-center navbar-brand m-0" to={"/"}>
                <img
                  // src={logo}
                  src={logo1}
                  className="navbar-brand-img"
                  alt="main_logo"
                  style={{ width: "120px" }}
                />
              </Link>
            </div>
          </>
        ) : (
          <>
            {/* MKPROXY */}
            <div className="sidenav-header">
              <i
                className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                aria-hidden="true"
                id="iconSidenav"
              ></i>
              <Link className="text-center navbar-brand m-0" to={"/"}>
                <img
                  src="../assets/img/server.png"
                  className="navbar-brand-img h-100"
                  alt="main_logo"
                />
                <span className="fs-4  ms-1 font-weight-bold">Mkproxy</span>
              </Link>
            </div>
          </>
        )}

        {/* MKPROXY */}

        <hr className="horizontal dark mt-0" />
        <div
          className="collapse navbar-collapse  w-auto "
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">
            {/* admin dashboard */}
            {(user?.group?.role === "admin" ||
              user?.group?.role === "superadmin") && (
              <li className="nav-item">
                <Link
                  className={adminToggle ? "nav-link active" : "nav-link"}
                  to="/admin/proxy-management"
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text ms-1">Proxy Management</span>
                </Link>
              </li>
            )}

            {/* customer dashboard */}
            {/* {user?.group?.role === "user" && (
              <>
                <li className="nav-item">
                  <Link
                    className={dashboardToggle ? "nav-link active" : "nav-link"}
                    to="/user/dashboard"
                  >
                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                    </div>
                    <span className="nav-link-text ms-1">Dashboard</span>
                  </Link>
                </li>
              </>
            )} */}

            {user?.group?.role === "reseller" && (
              <>
                <li className="nav-item">
                  <Link
                    className={
                      userManagementToggle ? "nav-link active" : "nav-link"
                    }
                    to="/reseller/user-management"
                  >
                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="ni ni-badge text-primary text-sm opacity-10"></i>
                    </div>
                    <span className="nav-link-text ms-1">User Management</span>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={
                      packageManagementToggle ? "nav-link active" : "nav-link"
                    }
                    to="/reseller/package-management"
                  >
                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="ni ni-archive-2 text-primary text-sm opacity-10"></i>
                    </div>
                    <span className="nav-link-text ms-1">
                      Package Management
                    </span>
                  </Link>
                </li>
              </>
            )}

            {user?.group?.role === "user" && (
              <>
                {/* User - Proxy Management */}
                <li className="nav-item">
                  <Link
                    className={
                      proxyManagementToggle ? "nav-link active" : "nav-link"
                    }
                    to="/user/proxy-management"
                  >
                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                    </div>
                    <span className="nav-link-text ms-1">Proxy Management</span>
                  </Link>
                </li>
                {/* User - Package Purchase */}
                <li className="nav-item">
                  <Link
                    className={
                      packagePurchaseToggle ? "nav-link active" : "nav-link"
                    }
                    to="/user/package-purchase"
                  >
                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="ni ni-archive-2 text-primary text-sm opacity-10"></i>
                    </div>
                    <span className="nav-link-text ms-1">Package Purchase</span>
                  </Link>
                </li>
                {/* User - Package Management */}
                <li className="nav-item">
                  <Link
                    className={
                      packageManagementToggle ? "nav-link active" : "nav-link"
                    }
                    to="/user/package-management"
                  >
                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="ni ni-laptop text-primary text-sm opacity-10"></i>
                    </div>
                    <span className="nav-link-text ms-1">
                      Package Management
                    </span>
                  </Link>
                </li>
                {/* Customer support */}
                <li className="nav-item">
                  <Link
                    className={
                      customerSupportToggle ? "nav-link active" : "nav-link"
                    }
                    to="/user/support"
                  >
                    <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                      <i className="ni ni-chat-round text-primary text-sm opacity-10"></i>
                    </div>
                    <span className="nav-link-text ms-1">Customer Support</span>
                  </Link>
                </li>
              </>
            )}

            {/* admin link */}
            {/* <li className="nav-item">
              <a className="nav-link " href="/admin">
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
                </div>
                <span className="nav-link-text ms-1">Admin</span>
              </a>
            </li> */}

            {/* profile link */}
            {/* <li className="nav-item">
              <a className="nav-link " href="/profile">
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="ni ni-single-02 text-dark text-sm opacity-10"></i>
                </div>
                <span className="nav-link-text ms-1">Profile</span>
              </a>
            </li> */}

            {/* User Packages */}
            {(user?.group?.role === "admin" ||
              user?.group?.role === "superadmin") && (
              <li
                className="nav-item"
                // onClick={() => {
                //   setUserPackagesToggle(true);
                //   setAdminToggle(false);
                // }}
              >
                <Link
                  className={
                    userPackagesToggle ? "nav-link active" : "nav-link"
                  }
                  to="/admin/user-packages"
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-bag-17 text-info text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text ms-1">
                    User Packages Management
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </aside>
      {/* <!-- end sidebar --> */}
    </>
  );
};

export default Sidebar1;
