import React from "react";
import Sidebar1 from "../components/sidebar1/Sidebar1";
import Navbar from "../components/navbar/Navbar";
import AuthContext from "../contexts/AuthContext";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const NotFound404 = () => {
  const authContext = useContext(AuthContext);

  const { user } = authContext;

  const navigate = useNavigate();

  if (!user) {
    return <Navigate to={"/login"} replace={"true"} />;
  }
  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="card-body px-0 pt-0 pb-2">
                  <div class="d-flex align-items-center justify-content-center vh-100">
                    <div class="text-center row">
                      <div class=" col-md-6">
                        <img
                          src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class=" col-md-6 mt-5">
                        <p class="fs-3">
                          {" "}
                          <span class="text-danger">Opps!</span> Page not found.
                        </p>
                        <p class="lead">
                          The page you’re looking for doesn’t exist.
                        </p>
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={() => navigate(-2)}>
                          Go Home
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default NotFound404;
