import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Sidebar1 from "../../components/sidebar1/Sidebar1";
import Navbar from "../../components/navbar/Navbar";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  StyledDataGrid,
  CustomPagination,
} from "../../components/styledDataGrid/StyledDataGrid";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../../contexts/AuthContext";
import { useContext } from "react";

const UserPackages = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  let [token, setToken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [userId, setUserId] = useState("64f6d3766a916c574924acba");
  const [packageDays, setPackageDays] = useState(30);
  const [proxyNumbers, setProxyNumbers] = useState(0);
  const [proxyType, setProxyType] = useState("ip6");
  const [userPackages, setUserPackages] = useState([]);
  const [resetUserPackagesFlag, setResetUserPackagesFlag] = useState(false);
  const [showPackageCreateModal, setShowPackageCreateModal] = useState(false);

  const handleClosePackageCreateModal = () => {
    setShowPackageCreateModal(false);
    // set states
  };
  const handleShowPackageCreateModal = () => setShowPackageCreateModal(true);

  const handleCreatePackage = (
    userId,
    packageDays,
    proxyNumbers,
    proxyType
  ) => {
    if (!userId) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your user id input cannot be empty!",
      });
    }

    if (!packageDays) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your package days input cannot be empty!",
      });
    } else {
      packageDays = Number(packageDays);

      if (packageDays < 0) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your package days must be larger than 0!",
        });
      }

      if (packageDays % 30 !== 0) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your package days must be divisible by 30!",
        });
      }
    }

    if (!proxyNumbers) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your proxy numbers input cannot be empty!",
      });
    } else {
      proxyNumbers = Number(proxyNumbers);
      if (proxyNumbers <= 0) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your proxy numbers input must be larger than 0!",
        });
      }
    }

    if (!proxyType) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your proxy type input cannot be empty!",
      });
    }

    // axios.post("/api/net/user-packages");
  };

  const ConfirmPackage = (packageId) => {
    Swal.fire({
      title: "Are you sure you want to confirm for this package?",
      text: `id: ${packageId}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#2dce89",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`/api/v1/admin/user-packages/${packageId}/confirm`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setResetUserPackagesFlag((prevState) => !prevState);
              Swal.fire(
                "Confirmed!",
                `The package with id: ${packageId} has been confirmed.`,
                "success"
              );
            }
          })
          .catch((err) => {
            console.error(err);
            return Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Message: " + err?.response?.data?.message,
            });
          });
      }
    });
  };

  const handleSearchByKeyword = async (e) => {
    e.preventDefault();
    if (token) {
      axios
        .get(
          `/api/v1/admin/user-packages/all?keyword=` +
            e.target.inputFilter.value,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setUserPackages(res.data.data.items);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (token) {
      axios
        .get(`/api/v1/admin/user-packages/all`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setUserPackages(res.data.data.items);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [resetUserPackagesFlag]);

  useEffect(() => {
    userPackages.map((userPackage, index) => {
      userPackage.serial = index + 1;

      // thousand seperator to price
      userPackage.price = userPackage.price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      // thousand seperator to final price
      userPackage.final_price = userPackage.final_price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    });
  }, [userPackages]);

  // Data grid
  const PAGE_SIZE = 20;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  let columns = useMemo(() => {
    // columns using for admin role
    const result = [
      // { field: "serial", headerName: "ID", width: 80 },
      // { field: "user_id", headerName: "USER ID", width: 260 },
      {
        field: "banking_code",
        headerName: "Banking Code",
        width: 160,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center">
              {params.row.confirmed === true && <span>{params.value}</span>}
              {params.row.confirmed === false && (
                <span className="text-danger">{params.value}</span>
              )}
            </div>
          );
        },
      },
      {
        field: "user_obj",
        headerName: "User",
        width: 150,
        renderCell: (params) => {
          return params?.value?.email;
        },
      },
      {
        field: "referer_obj",
        headerName: "Referer",
        width: 120,
        renderCell: (params) => {
          return params?.value?.email;
        },
      },
      {
        field: "package_name",
        headerName: "Package Name",
        width: 129,
        renderCell: (params) => {
          return <span className="text-capitalize">{params.value}</span>;
        },
      },
      {
        field: "package_number",
        headerName: "Package Numbers",
        width: 142,
        renderCell: (params) => {
          return <span className="text-capitalize">{params.value}</span>;
        },
      },
      { field: "package_days", headerName: "Package Days", width: 120 },
      // { field: "proxy_number", headerName: "PROXY NUMBERS", width: 135 },
      { field: "proxy_type", headerName: "Type", width: 50 },
      { field: "proxy_version", headerName: "Version", width: 75 },
      { field: "price", headerName: "Price (VND)", width: 100 },
      { field: "discount", headerName: "Discount (%)", width: 110 },
      { field: "final_price", headerName: "Final Price (vnd)", width: 140 },
      { field: "started_at", headerName: "Start Package", width: 150 },
      { field: "expired_at", headerName: "End Package", width: 150 },
      {
        field: "confirmed",
        headerName: "Confirmed",
        width: 100,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center">
              {params.value === true && (
                <span class="c-pill c-pill--success">True</span>
              )}
              {params.value === false && (
                <span class="c-pill c-pill--danger">False</span>
              )}
            </div>
          );
        },
      },
      // { field: "confirmed", headerName: "CONFIRMED", width: 150 },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center justify-content-center my-2 w-100">
              {!params.row.confirmed && (
                <Button
                  className="my-0 px-0 px-3 btn-sm ms-2 shadow"
                  variant="outline-success"
                  onClick={() => ConfirmPackage(params.row.id)}
                >
                  Confirm
                </Button>
              )}
            </div>
          );
        },
      },
    ];

    return result;
  }, []);
  // end of Data grid

  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                {/* card header */}
                <div className="card-header text-uppercase pb-0">
                  <h5>User Packages</h5>
                  <div className="border-top d-none">
                    <button
                      type="button"
                      onClick={handleShowPackageCreateModal}
                      className=" my-2 border-0 badge badge-sm bg-gradient-success d-flex align-items-center"
                    >
                      <ControlPointIcon className="fs-4" />{" "}
                      <span className="ms-1">Create</span>
                    </button>
                  </div>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <div>
                      <span className="text-capitalize">Total:</span>
                      <span className="ms-2 text-bold">
                        {userPackages.length}
                      </span>
                    </div>
                    {user?.group?.role !== "user" && (
                      <div style={{ maxWidth: 200 }}>
                        <Form onSubmit={handleSearchByKeyword}>
                          <Form.Control
                            type="text"
                            id="inputFilter"
                            htmlSize="200"
                            name="inputFilter"
                            placeholder="Filter..."
                          />
                        </Form>
                      </div>
                    )}
                  </div>
                </div>

                {/* card body */}
                <div className="card-body border-top px-0 pt-0 pb-2">
                  <StyledDataGrid
                    //
                    sx={{
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                          display: "none",
                        },
                      "& .css-i4bv87-MuiSvgIcon-root": {
                        display: "none",
                      },
                    }}
                    rowHeight={45}
                    // test
                    // getRowHeight={() => "auto"}
                    rows={userPackages}
                    columns={columns}
                    // test
                    checkboxSelection
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}
                    slots={{
                      pagination: CustomPagination,
                    }}
                    {...userPackages}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* MODALS */}
      {/* PACKAGE-CREATE MODAL */}
      {/* <Modal
        show={showPackageCreateModal}
        onHide={handleClosePackageCreateModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-capitalize">
            Create A User Package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>USER ID</Form.Label>
              <Form.Control
                type="text"
                value={userId}
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
                placeholder="Enter user id"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>PACKAGE DAYS (divisible by 30)</Form.Label>
              <Form.Control
                type="number"
                value={packageDays}
                onChange={(e) => setPackageDays(e.target.value)}
                placeholder="Enter package days"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>PROXY NUMBERS</Form.Label>
              <Form.Control
                type="number"
                value={proxyNumbers}
                onChange={(e) => setProxyNumbers(e.target.value)}
                placeholder="Enter proxy numbers"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>PROXY TYPE</Form.Label>
              <Form.Select
                disabled
                value={proxyType}
                // onChange={(e) => setProxyType(e.target.value)}
              >
                <option value="ip6">ip6</option>
                <option value="ip4">ip4</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePackageCreateModal}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() =>
              handleCreatePackage(userId, packageDays, proxyNumbers, proxyType)
            }>
            Create Package
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* END OF PACKAGE-CREATE MODAL */}
      {/* END OF MODALS */}
    </React.Fragment>
  );
};

export default UserPackages;
