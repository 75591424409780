import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import axios from "axios";
import Sidebar1 from "../../components/sidebar1/Sidebar1";
import Navbar from "../../components/navbar/Navbar";
import {
  StyledDataGrid,
  CustomPagination,
} from "../../components/styledDataGrid/StyledDataGrid";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

const LineProxyList = () => {
  // contexts
  const authContext = useContext(AuthContext);
  const { user, authTokens } = authContext;

  const { proxy_line_id } = useParams();
  const [proxies, setProxies] = useState([]);
  const [usedCount, setUsedCount] = useState(0);

  const ListLineProxies = (proxyLineId, token) => {
    axios
      .get(`/api/v1/admin/proxy-generations/${proxyLineId}/list`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setProxies(res?.data?.data?.items);
        }
      })
      .catch((err) => {
        console.error("Something went wrong!", err);
      });
  };

  useLayoutEffect(() => {
    if (authTokens) {
      let token = authTokens;
      ListLineProxies(proxy_line_id, token);
    }
  }, []);

  useEffect(() => {
    proxies.map((proxy, index) => {
      proxy.serial = index + 1;
    });
  }, [proxies]);

  useEffect(() => {
    let count = 0;
    proxies.map((proxy) => {
      if (proxy.assigned === true) {
        count += 1;
      }
    });
    setUsedCount(count);
  }, [proxies]);

  const PAGE_SIZE = 20;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const columns = useMemo(() => {
    const result = [
      { field: "serial", headerName: "ID", width: 100 },
      { field: "proxy_url", headerName: "URL", width: 300 },
      { field: "port", headerName: "PORT", width: 200 },
      { field: "proxy_type", headerName: "TYPE", width: 200 },
      { field: "proxy_version", headerName: "VERSION", width: 200 },
      { field: "ip6", headerName: "IP6", width: 300 },
      { field: "ip4", headerName: "IP4", width: 300 },
    ];
    return result;
  }, []);

  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                {/* card header */}
                <div className="card-header text-uppercase pb-0">
                  <h5>Proxy list of id: {proxy_line_id}</h5>
                  {proxies.length === 0 ? (
                    <h6 className="text-danger">
                      Id does not exist or The proxy has not been generated yet.
                    </h6>
                  ) : (
                    <div className="d-flex">
                      <h6>
                        <span className="fw-normal">Total: </span>
                        <span className="fw-bold">{proxies.length} </span>
                      </h6>
                      <span className="ms-1">/</span>
                      <h6 className="ms-1">
                        <span className="fw-normal"> Used: </span>
                        <span className="fw-bold">{usedCount}</span>
                      </h6>
                    </div>
                  )}
                </div>

                {/* card body */}
                <div className="card-body px-0 pt-0 pb-2">
                  <StyledDataGrid
                    // test
                    getRowHeight={() => "auto"}
                    rows={proxies}
                    columns={columns}
                    // test
                    checkboxSelection
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}
                    slots={{
                      pagination: CustomPagination,
                    }}
                    {...proxies}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default LineProxyList;
