import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Sidebar1 from "../../../components/sidebar1/Sidebar1";
import Navbar from "../../../components/navbar/Navbar";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  StyledDataGrid,
  CustomPagination,
} from "../../../components/styledDataGrid/StyledDataGrid";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { numberWithCommas } from "../../../util/util";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";

const PackagePurchase = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  let [token, setToken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [showBuyPackageModal, setShowBuyPackageModal] = useState(false);
  const [packages, setPackages] = useState([]);
  const [resetPackages, setResetPackages] = useState(false);
  const [packageForSellInfo, setPackageForSellInfo] = useState({});
  const [packageDetail, setPackageDetail] = useState([]);
  const [isShowedEditModal, setIsShowedEditlModal] = useState(false);
  const [packageProxyType, setPackageProxyType] = useState("");

  const handleGetPackageInfo = () => {
    if (user?.referer) {
      axios
        .get(`/api/v1/user-packages/info?referer_id=${user.referer.id}`)
        .then((res) => {
          if (res.status === 200) {
            // setPackageForSellInfo(res.data.data);
            let sortedKeys = Object.keys(res.data.data).sort(
              (a, b) => res.data.data[a].sort - res.data.data[b].sort
            );
            let sortedData = sortedKeys.reduce((obj, key) => {
              obj[key] = res.data.data[key];
              return obj;
            }, {});

            setPackageForSellInfo(sortedData);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .get("/api/v1/user-packages/info")
        .then((res) => {
          if (res.status === 200) {
            // setPackageForSellInfo(res.data.data);

            let sortedKeys = Object.keys(res.data.data).sort(
              (a, b) => res.data.data[a].sort - res.data.data[b].sort
            );
            let sortedData = sortedKeys.reduce((obj, key) => {
              obj[key] = res.data.data[key];
              return obj;
            }, {});

            setPackageForSellInfo(sortedData);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    handleGetPackageInfo();
  }, []);

  useEffect(() => {
    console.log(packageForSellInfo);
  }, [packageForSellInfo]);

  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="card-header text-uppercase pb-0">
                  <h5>Rotating Residential Proxy Packages</h5>
                  <div className="border-top d-none"></div>
                  <div className="mb-2 d-flex justify-content-between align-items-center"></div>
                </div>

                <div
                  className="card-body border-top px-0 pt-0 pb-2"
                  style={{ height: 690, width: "100%" }}
                >
                  <div class="demo mt-5 mb-5">
                    <div class="container">
                      {/* <div class="row">
                        <div class="col-md-2 col-sm-3"></div>
                        <div class="col-md-4 col-sm-6 mx-2">
                          <div class="pricingTable">
                            <div class="pricingTable-header">
                              <i class="fa fa-adjust"></i>
                              <div class="price-value">
                                {packageForSellInfo?.standard
                                  ?.price_per_month &&
                                  numberWithCommas(
                                    packageForSellInfo?.standard
                                      ?.price_per_month -
                                      packageForSellInfo?.standard
                                        ?.price_per_month *
                                        (packageForSellInfo?.standard
                                          ?.discount /
                                          100)
                                  )}
                                đ<span class="month fw-bolder">/1 month</span>
                              </div>
                              <span class="month">(~30 days)</span>
                            </div>
                            <h3 class="heading">
                              {packageForSellInfo?.standard?.name}
                            </h3>
                            <div class="pricing-content">
                              <ul>
                                <li>
                                  Quantity:{" "}
                                  <b>
                                    {packageForSellInfo?.standard?.total_proxy}
                                  </b>
                                </li>
                                <li>
                                  Bandwidth: <b>Unlimited</b>
                                </li>
                                {packageForSellInfo?.standard?.discount !=
                                  0 && (
                                  <>
                                    <li>
                                      Discount:{" "}
                                      <b>
                                        {packageForSellInfo?.standard?.discount}
                                        %
                                      </b>
                                    </li>
                                    <li>
                                      Original Price:{" "}
                                      <b>
                                        {packageForSellInfo?.vip
                                          ?.price_per_month &&
                                          numberWithCommas(
                                            packageForSellInfo?.standard
                                              ?.price_per_month
                                          )}
                                        đ
                                      </b>
                                    </li>
                                  </>
                                )}

                                <li>
                                  Proxy: <b>HTTP</b>, <b>SOCK5</b>
                                </li>
                              </ul>
                            </div>
                            <div class="pricingTable-signup">
                              <Link to="/proxy-products/order/package_number=1&proxy_type=http&package_name=standard">
                                Buy Package
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 mx-2">
                          <div class="pricingTable green">
                            <div class="wrap">
                              <span class="ribbon6 text-danger fw-bold">
                                Best Seller
                              </span>
                            </div>
                            <div class="pricingTable-header">
                              <i class="fa fa-briefcase"></i>
                              <div class="price-value">
                                {packageForSellInfo?.vip?.price_per_month &&
                                  numberWithCommas(
                                    packageForSellInfo?.vip?.price_per_month -
                                      packageForSellInfo?.vip?.price_per_month *
                                        (packageForSellInfo?.vip?.discount /
                                          100)
                                  )}
                                đ<span class="month fw-bold">/1 month</span>
                              </div>
                              <span class="month">(~30 days)</span>
                            </div>
                            <h3 class="heading">
                              {packageForSellInfo?.vip?.name}
                            </h3>
                            <div class="pricing-content">
                              <ul>
                                <li>
                                  Quantity:{" "}
                                  <b>{packageForSellInfo?.vip?.total_proxy}</b>
                                </li>
                                <li>
                                  Bandwidth: <b>Unlimited</b>
                                </li>
                                {packageForSellInfo?.standard?.discount !=
                                  0 && (
                                  <>
                                    <li>
                                      Discount:{" "}
                                      <b>
                                        {packageForSellInfo?.vip?.discount}%
                                      </b>
                                    </li>
                                    <li>
                                      Original Price:{" "}
                                      <b>
                                        {packageForSellInfo?.vip
                                          ?.price_per_month &&
                                          numberWithCommas(
                                            packageForSellInfo?.vip
                                              ?.price_per_month
                                          )}
                                        đ
                                      </b>
                                    </li>
                                  </>
                                )}

                                <li>
                                  Proxy: <b>HTTP</b>, <b>SOCK5</b>
                                </li>
                              </ul>
                            </div>
                            <div class="pricingTable-signup">
                              <Link to="/proxy-products/order/package_number=1&proxy_type=http&package_name=vip">
                                Buy Package
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-3"></div>
                      </div> */}
                      {/* TEST */}
                      <div class="row">
                        {Object.keys(packageForSellInfo).map((key) => (
                          <div class="col-md-4 col-sm-6" key={key}>
                            <div
                              class={
                                packageForSellInfo[key]["best_seller"]
                                  ? "pricingTable"
                                  : "pricingTable green"
                              }
                            >
                              {packageForSellInfo[key]["best_seller"] && (
                                <div class="wrap">
                                  <span class="ribbon6 text-danger fw-bold">
                                    Best Seller
                                  </span>
                                </div>
                              )}

                              <div class="pricingTable-header">
                                <i class="fa fa-adjust"></i>
                                <div class="price-value">
                                  {numberWithCommas(
                                    (packageForSellInfo[key][
                                      "price_per_month"
                                    ] *
                                      (100 -
                                        packageForSellInfo[key]["discount"])) /
                                      100
                                  )}
                                  đ<span class="month fw-bolder">/1 month</span>
                                </div>
                                <span class="month">(~30 days)</span>
                              </div>
                              <h3 class="heading">
                                {packageForSellInfo[key]["name"]}
                              </h3>
                              <div class="pricing-content">
                                <ul>
                                  <li>
                                    Quantity:{" "}
                                    <b>
                                      {packageForSellInfo[key]["total_proxy"]}
                                    </b>
                                  </li>
                                  <li>
                                    Bandwidth: <b>Unlimited</b>
                                  </li>
                                  {packageForSellInfo[key]["discount"] != 0 && (
                                    <>
                                      <li>
                                        Discount:{" "}
                                        <b>
                                          {packageForSellInfo[key]["discount"]}%
                                        </b>
                                      </li>
                                      <li>
                                        Original Price:{" "}
                                        <b>
                                          {numberWithCommas(
                                            packageForSellInfo[key][
                                              "price_per_month"
                                            ]
                                          )}
                                          đ
                                        </b>
                                      </li>
                                    </>
                                  )}

                                  <li>
                                    Proxy: <b>HTTP</b>, <b>SOCK5</b>
                                  </li>
                                </ul>
                              </div>
                              <div class="pricingTable-signup">
                                <Link
                                  to={`/proxy-products/order/package_number=1&proxy_type=http&package_name=${key}`}
                                >
                                  Buy Package
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default PackagePurchase;
