import React from "react";
import "./underMaintenance.css";
import AuthContext from "../../contexts/AuthContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

const UnderMaintenance = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  if (!user) {
    return <Navigate to={"/login"} replace="true" />;
  }

  return (
    <>
      <div class="maintenance">
        <div class="maintenance_contain">
          <div className="">
            <img
              src="https://demo.wpbeaveraddons.com/wp-content/uploads/2018/02/main-vector.png"
              alt="maintenance"
            />
          </div>
          <i class="fa fa-6x fa-cog fa-spin"></i>
          <span class="pp-infobox-title-prefix">WE ARE COMING SOON</span>
          <div class="pp-infobox-title-wrapper">
            <h3 class="pp-infobox-title">The website under maintenance!</h3>
          </div>

          <span class="title-text pp-primary-title">
            Please try again later!
          </span>
          <div class="pp-social-icons pp-social-icons-center pp-responsive-center">
            {/* <span class="pp-social-icon">
                <link itemprop="url" href="#" />
                <a
                  itemprop="sameAs"
                  href="#"
                  target="_blank"
                  title="Facebook"
                  aria-label="Facebook"
                  role="button">
                  <i class="fa fa-facebook"></i>
                </a>
              </span> */}
            {/* <span class="pp-social-icon">
                <link itemprop="url" href="#" />
                <a
                  itemprop="sameAs"
                  href="#"
                  target="_blank"
                  title="Twitter"
                  aria-label="Twitter"
                  role="button">
                  <i class="fa fa-twitter"></i>
                </a>
              </span>
              <span class="pp-social-icon">
                <link itemprop="url" href="#" />
                <a
                  itemprop="sameAs"
                  href="#"
                  target="_blank"
                  title="Google Plus"
                  aria-label="Google Plus"
                  role="button">
                  <i class="fa fa-google-plus"></i>
                </a>
              </span>
              <span class="pp-social-icon">
                <a
                  itemprop="sameAs"
                  href="#"
                  target="_blank"
                  title="LinkedIn"
                  aria-label="LinkedIn"
                  role="button">
                  <i class="fa fa-linkedin"></i>
                </a>
              </span> */}
          </div>
        </div>
        <div className="test">
          <i class="fa fa-spinner fa-spin ms-1"></i>
          <i class="fa fa-circle-o-notch fa-spin ms-1"></i>
          <i class="fa fa-refresh fa-spin ms-1"></i>
          <i class="fa fa-cog fa-spin ms-1"></i>
        </div>
      </div>
    </>
  );
};

export default UnderMaintenance;
