import React, { useRef, useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

const Socks5Tab = () => {
  const [socks5Text, setSocks5Text] = useState("");

  const textareaRef = useRef(null);

  const handleSocks5TextChange = (event) => {
    // 👇️ access textarea value
    setSocks5Text(event.target.value);
    console.log(event.target.value);
  };

  function focusTextarea() {
    textareaRef.current.focus();
  }

  useEffect(() => {
    focusTextarea();
  });

  return (
    <div class="row mt-5 px-4">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header text-start pb-0">
            <h6>Total: 0 Port</h6>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <TextareaAutosize
              minRows={20}
              placeholder="No active sockets"
              defaultValue=""
              value={socks5Text}
              ref={textareaRef}
              onChange={handleSocks5TextChange}
              style={{ width: "96%", borderColor: "lightblue" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socks5Tab;
