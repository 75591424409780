import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Navbar = () => {
  const authContext = useContext(AuthContext);
  const { user, logoutUser } = authContext;

  // states
  const [userDropdown, setUserDropDown] = useState(false);

  return (
    <React.Fragment>
      {/* <!-- main navbar --> */}
      <main class="main-content position-relative border-radius-lg ">
        {/* <!-- Navbar --> */}
        <nav
          class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl "
          id="navbarBlur"
          data-scroll="false">
          <div class="container-fluid py-1 px-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                <li class="breadcrumb-item text-sm">
                  <a class="opacity-5 text-white" href="javascript:;">
                    Pages
                  </a>
                </li>
                <li
                  class="breadcrumb-item text-sm text-white active"
                  aria-current="page">
                  Dashboard
                </li>
              </ol>
              <h6 class="font-weight-bolder text-white mb-0">Dashboard </h6>
            </nav>

            {/* testing ... */}
            <div
              class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
              id="navbar">
              {/* search */}
              <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                {/* <div class="input-group">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type here..."
                  />
                </div> */}
              </div>

              {/* dropdrown */}
              {/* if singed in then show */}
              {user && (
                <ul class="navbar-nav justify-content-end">
                  <BootstrapTooltip>
                    <li class="nav-item dropdown pe-2 d-flex align-items-center position-relative border">
                      <a
                        class="nav-link text-white p-0"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // onClick={() =>
                        //   setUserDropDown((prevState) => !prevState)
                        // }
                      >
                        <div class="d-flex py-1">
                          <div class="my-auto">
                            <img
                              src="../assets/img/user-icon-1.jpg"
                              class="avatar avatar-sm  me-3 "
                            />
                          </div>
                          <div class="d-flex flex-column align-items-center justify-content-center cursor-pointer">
                            <h6 class="text-sm font-weight-normal mb-1">
                              <span>hello </span>
                              <span className="font-weight-bold fs-6">
                                {user?.email}
                              </span>
                              <KeyboardArrowDownIcon className="text-secondary" />
                            </h6>
                          </div>
                        </div>
                      </a>
                      {/* {userDropdown && ( */}
                      <ul
                        // class="dropdown-menu1  dropdown-menu-end1 me-sm-n4 position-absolute top-0 end-50 mt-5 border rounded-pill list-unstyled p-0 py-1 px-4"
                        class="dropdown-menu dropdown-menu-end mt-0 me-3 shadow-lg"
                        style={{ zIndex: 6 }}
                        aria-labelledby="dropdownMenuButton">
                        {/* User infomation button */}
                        {/* <li class="mb-2">
                          <div
                            class="dropdown-item border-radius-md"
                            // href="javascript:;"
                          >
                            <div class="d-flex py-1">
                              <div class="my-auto">
                                <PermIdentityIcon className="text-info" />
                              </div>
                              <div class="ms-3 d-flex flex-column justify-content-center">
                                <h6 class="text-sm font-weight-normal mb-1">
                                  <span class="font-weight-bold text-center">
                                    My Profile
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </li> */}
                        {/* logout button */}

                        {/* {user?.group?.role === "user" && (
                          <li class="mb-2 cursor-pointer">
                            <div
                              class="dropdown-item border-radius-md"
                              // href="javascript:;"
                            >
                              <Link
                                className="d-flex py-1"
                                to={"/proxy-products"}>
                                <div class="my-auto">
                                  <LocalMallIcon className="text-warning" />
                                </div>
                                <div class="ms-3 d-flex flex-column justify-content-center">
                                  <h6 class="text-sm font-weight-normal mb-1">
                                    <span class="font-weight-bold text-center">
                                      Buy Packages
                                    </span>
                                  </h6>
                                </div>
                              </Link>
                            </div>
                          </li>
                        )} */}

                        {/* User Dropdown */}

                        <li class="mb-2 cursor-pointer">
                          <div class="dropdown-item border-radius-md">
                            {/* Logout button */}
                            <div class="d-flex py-1" onClick={logoutUser}>
                              <div class="my-auto">
                                <LogoutIcon className="text-danger" />
                              </div>
                              <div class="ms-3 d-flex flex-column justify-content-center">
                                <h6 class="text-sm font-weight-normal mb-1">
                                  <span class="font-weight-bold text-center">
                                    Logout
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      {/* )} */}
                    </li>
                  </BootstrapTooltip>
                </ul>
              )}
            </div>
          </div>
        </nav>
        {/* <!-- End Navbar --> */}
      </main>
      {/* <!-- end main navbar --> */}
    </React.Fragment>
  );
};

export default Navbar;
