import React, { useEffect, useState } from "react";
import logo1 from "../../assets/img/proxy-10.png"; //boot24hproxy
import logo2 from "../../assets/img/mkproxy-logo-9.png"; //mkproxy
import { Link, Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

const Register = () => {
  const authContext = useContext(AuthContext);

  const {
    loginUser,
    registerUser,
    user,
    errors,
    setErrors,
    success,
    setSuccess,
    domain,
  } = authContext;

  const [registerFormData, setRegisterFormData] = useState({
    fullname: "",
    email: "",
    password: "",
    repeat_password: "",
    referer_id: "",
  });

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setRegisterFormData({
      ...registerFormData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!registerFormData.fullname.trim()) {
      validationErrors.fullname = "Full name is required";
    }

    if (!registerFormData.phone.trim()) {
      validationErrors.phone = "Phone is required";
    }

    if (!registerFormData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(registerFormData.email)) {
      validationErrors.email = "Email is not valid";
    }

    if (!registerFormData.password.trim()) {
      validationErrors.password = "Password is required";
    } else if (
      registerFormData.password.trim() !=
      registerFormData.repeat_password.trim()
    ) {
      validationErrors.repeat_password = "Password is not match";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      registerUser(
        registerFormData.fullname,
        registerFormData.phone,
        registerFormData.email,
        registerFormData.password
      );
    }
  };

  useEffect(() => {
    if (success["code"] === 200) {
      setTimeout(() => {
        loginUser(registerFormData.email, registerFormData.password);
      }, 2000);
    }
  }, [success]);

  // If user role is user then redirect to dashboard route
  // If user role is admin then redirect to admin route
  if (user) {
    if (user?.group?.role === "user") {
      return <Navigate to="/user/dashboard" />;
    } else if (
      user?.group?.role === "admin" ||
      user?.group?.role === "superadmin"
    ) {
      return <Navigate to="/admin/proxy-management" />;
    } else if (user?.group?.role === "reseller") {
      return <Navigate to="/reseller/user-management" />;
    }
  } else {
    return (
      <React.Fragment>
        <main class="main-content  mt-0">
          <section>
            <div class="page-header min-vh-100">
              <div class="container">
                <div class="row">
                  <div class="col-xl-5 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                    <div className="text-center">
                      {domain == "boost24h" ? (
                        <img src={logo1} className="w-90" />
                      ) : (
                        <img src={logo2} className="w-60" />
                      )}
                    </div>
                    <div class="card card-plain">
                      <div class="card-header pb-0 text-start">
                        <h4 class="font-weight-bolder">Create a new account</h4>
                        <p class="mb-0">
                          Enter your infomation to create a new one!
                        </p>
                      </div>
                      <div class="card-body">
                        <form
                          role="form"
                          novalidate="novalidate"
                          onSubmit={handleSubmit}
                        >
                          <div class="mb-3">
                            <label class="form-check-label" for="fullname">
                              Full name
                            </label>
                            <input
                              class="form-control form-control-lg"
                              type="text"
                              name="fullname"
                              placeholder="Your fullname"
                              autoComplete="off"
                              // value={form.email}
                              onChange={handleChange}
                              id="fullname"
                            />
                            {errors.fullname && (
                              <span
                                className="text-danger ms-2"
                                style={{ fontSize: "0.8rem" }}
                              >
                                {errors.fullname}
                              </span>
                            )}
                          </div>
                          <div class="mb-3">
                            <label class="form-check-label" for="phone">
                              Phone
                            </label>
                            <input
                              class="form-control form-control-lg"
                              type="text"
                              name="phone"
                              placeholder="Your phone numbers"
                              autoComplete="off"
                              // value={form.phone}
                              onChange={handleChange}
                              id="phone"
                            />
                            {errors.phone && (
                              <span
                                className="text-danger ms-2"
                                style={{ fontSize: "0.8rem" }}
                              >
                                {errors.phone}
                              </span>
                            )}
                          </div>
                          <div class="mb-3">
                            <label class="form-check-label" for="email">
                              Email
                            </label>
                            <input
                              class="form-control form-control-lg"
                              type="email"
                              name="email"
                              placeholder="Email"
                              autoComplete="off"
                              // value={form.email}
                              onChange={handleChange}
                              aria-label="Email"
                              id="email"
                            />
                            {errors.email && (
                              <span
                                className="text-danger ms-2"
                                style={{ fontSize: "0.8rem" }}
                              >
                                {errors.email}
                              </span>
                            )}
                          </div>
                          <div class="mb-3">
                            <label class="form-check-label" for="password">
                              Password
                            </label>
                            <input
                              type="password"
                              class="form-control form-control-lg"
                              placeholder="******"
                              // value={form.password}
                              onChange={handleChange}
                              aria-label="Password"
                              name="password"
                              id="password"
                            />
                            {errors.password && (
                              <span
                                className="text-danger ms-2"
                                style={{ fontSize: "0.9rem" }}
                              >
                                {errors.password}
                              </span>
                            )}
                          </div>

                          <div class="mb-3">
                            <label class="form-check-label" for="password">
                              Repeat password
                            </label>
                            <input
                              type="password"
                              class="form-control form-control-lg"
                              placeholder="******"
                              value={registerFormData.repeat_password}
                              onChange={handleChange}
                              aria-label="Password"
                              name="repeat_password"
                              id="repeat_password"
                            />
                            {errors.repeat_password && (
                              <span
                                className="text-danger ms-2"
                                style={{ fontSize: "0.9rem" }}
                              >
                                {errors.repeat_password}
                              </span>
                            )}
                          </div>

                          <div class="mt-1">
                            <label class="form-check-label fs-6">
                              Already have an account?
                            </label>{" "}
                            <Link
                              to="/login"
                              className="form-check-label text-primary fs-6"
                            >
                              Log in
                            </Link>
                          </div>

                          {errors?.submit && (
                            <span
                              style={{ fontSize: "0.9rem" }}
                              className="text-danger"
                            >
                              {errors.submit}
                            </span>
                          )}

                          {success?.submit && (
                            <span
                              style={{ fontSize: "0.9rem" }}
                              className="text-success"
                            >
                              {success.submit}
                            </span>
                          )}

                          <div class="text-center">
                            <button
                              type="submit"
                              class="btn btn-lg btn-success btn-lg w-100 mt-4 mb-0"
                            >
                              Create
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                    <div
                      class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                      style={{
                        backgroundImage:
                          // "url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg')",
                          // "url('https://techrab.com/wp-content/uploads/2022/02/Proxy-Server-PS4.gif')",
                          "url('../assets/img/proxy-backgound.gif')",
                        backgroundSize: "cover",
                      }}
                    >
                      {/* <span class="mask bg-gradient-info opacity-6"></span> */}
                      {/* <h4 class="mt-5 text-white font-weight-bolder position-relative">
                        "Attention is the new currency"
                      </h4>
                      <p class="text-white position-relative">
                        The more effortless the writing looks, the more effort the
                        writer actually put into the process.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
};

export default Register;
