import React from "react";
import "./buyPackage.css";
import { Link } from "react-router-dom";
import Sidebar1 from "../../../components/sidebar1/Sidebar1";
import Navbar from "../../../components/navbar/Navbar";

const BuyPackage = () => {
  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                {/* card header */}
                <div className="card-header text-center text-uppercase pb-0 mt-2 mb-4">
                  <div className="bg-light rounded py-3">
                    <h2 className="fw-bold">PROXY IPV6</h2>
                  </div>
                </div>

                {/* card body */}
                <div className="card-body px-0 pt-0 py-5 bg-light rounded">
                  <div class="demo mt-5 mb-5">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-3 col-sm-6">
                          <div class="pricingTable">
                            <div class="pricingTable-header">
                              <i class="fa fa-adjust"></i>
                              <div class="price-value">
                                306.000đ
                                <span class="month fw-bolder">/1 tháng</span>
                              </div>
                              <span class="month">(1 tháng = 30 ngày)</span>
                            </div>
                            <h3 class="heading">Standard</h3>
                            <div class="pricing-content">
                              <ul>
                                <li>
                                  Số Lượng: <b>100 proxy</b>
                                </li>
                                <li>
                                  Bandwidth: <b>Unlimited</b>
                                </li>
                                <li>
                                  khuyến mãi: <b>15%</b>
                                </li>
                                <li>
                                  giá gốc: <b>360.000đ</b>
                                </li>
                              </ul>
                            </div>
                            <div class="pricingTable-signup">
                              <Link to="/proxy-products/order/package_days=30&proxy_number=100">
                                Mua ngay
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                          <div class="pricingTable green">
                            <div class="wrap">
                              <span class="ribbon6 text-danger fw-bold">
                                Best Seller
                              </span>
                            </div>
                            <div class="pricingTable-header">
                              <i class="fa fa-briefcase"></i>
                              <div class="price-value">
                                612.000đ
                                <span class="month fw-bold">/2 tháng</span>
                              </div>
                              <span class="month">(2 tháng = 60 ngày)</span>
                            </div>
                            <h3 class="heading">Business</h3>
                            <div class="pricing-content">
                              <ul>
                                <li>
                                  Số Lượng: <b>100 proxy</b>
                                </li>
                                <li>
                                  Bandwidth: <b>Unlimited</b>
                                </li>
                                <li>
                                  khuyến mãi: <b>15%</b>
                                </li>
                                <li>
                                  giá gốc: <b>720.000đ</b>
                                </li>
                              </ul>
                            </div>
                            <div class="pricingTable-signup">
                              <Link to="/proxy-products/order/package_days=60&proxy_number=100">
                                Mua Ngay
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3 col-sm-6">
                          <div class="pricingTable blue">
                            <div class="pricingTable-header">
                              <i class="fa fa-diamond"></i>
                              <div class="price-value">
                                918.000đ <span class="month">/3 tháng</span>
                              </div>
                              <span className="month">(3 tháng = 90 ngày)</span>
                            </div>
                            <h3 class="heading">Premium</h3>
                            <div class="pricing-content">
                              <ul>
                                <li>
                                  Số Lượng: <b>100 proxy</b>
                                </li>
                                <li>
                                  Bandwidth: <b>Unlimited</b>
                                </li>
                                <li>
                                  khuyến mãi: <b>15%</b>
                                </li>
                                <li>
                                  giá gốc: <b>1.080.000đ</b>
                                </li>
                              </ul>
                            </div>
                            <div class="pricingTable-signup">
                              <Link to="/proxy-products/order/package_days=90&proxy_number=100">
                                Mua Ngay
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3 col-sm-6">
                          <div class="pricingTable red">
                            <div class="pricingTable-header">
                              <i class="fa fa-cube"></i>
                              <div class="price-value">
                                3.672.000đ <span class="month">/1 năm</span>
                              </div>
                              <span className="month">(1 năm = 360 ngày)</span>
                            </div>
                            <h3 class="heading">Extra</h3>
                            <div class="pricing-content">
                              <ul>
                                <li>
                                  Số Lượng: <b>100 proxy</b>
                                </li>
                                <li>
                                  Bandwidth: <b>Unlimited</b>
                                </li>
                                <li>
                                  khuyến mãi: <b>15%</b>
                                </li>
                                <li>
                                  giá gốc: <b>4.320.000đ</b>
                                </li>
                              </ul>
                            </div>
                            <div class="pricingTable-signup">
                              <Link to="/proxy-products/order/package_days=360&proxy_number=100">
                                Mua Ngay
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* test */}
                  {/* <div class="ribbon"> */}
                  <div class="wrap">
                    <span class="ribbon6 text-bold text-danger">
                      Giá siêu tốt
                    </span>
                  </div>
                  {/* </div> */}
                  {/* test */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default BuyPackage;
