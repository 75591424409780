import React, { useState, useEffect, useRef } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useContext } from "react";
import axios from "axios";
// new
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { GridCellParams } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
// import Button from '@mui/material/Button' as btn;
// new
function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  ...customCheckbox(theme),
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

// set page size for data grid
const PAGE_SIZE = 20;
// new

// test
const columns = [
  { field: "proxy_url", headerName: "Proxy", width: 260 },
  { field: "port", headerName: "Port", width: 160 },
  { field: "ip6", headerName: "Ipv6", width: 400 },
  { field: "proxy_type", headerName: "Type", width: 200 },
  { field: "proxy_version", headerName: "Version", width: 160 },
  // { field: "rotate", headerName: "ROTATION TIME", width: 200 },
  // {
  //   field: "expired_at",
  //   headerName: "REMAINING DAYS",
  //   width: 180,
  //   renderCell: (params) => {
  //     let expiredDate = new Date(params.value);
  //     let currentDate = new Date();
  //     let difference = expiredDate.getTime() - currentDate.getTime();
  //     let remainingDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
  //     return <span>{remainingDays}</span>;
  //   },
  // },
];
// test

const IpProxyTab = ({ proxies, proxyType, proxyConfig }) => {
  // contexts
  const authContext = useContext(AuthContext);
  const { user, getProfile } = authContext;
  // states
  const [tableData, setTableData] = useState(proxies);

  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const hasCopiedText = Boolean(copiedText);

  const [copiedProxies, setCopiedProxies] = useState([]);
  const [copiedFlag, setCopiedFlag] = useState(false);

  const [showSuccess, setShowSuccess] = useState("");

  let CopyProxies = () => {
    let allProxiesString = "";
    if (proxyType == "http" && proxyConfig["auth_user"].length > 0) {
      let authProxyCredentials = proxyConfig["auth_user"].split("|");
      copiedProxies.map((obj, index) => {
        allProxiesString +=
          obj?.proxy_url +
          ":" +
          obj?.port +
          ":" +
          authProxyCredentials[0] +
          ":" +
          authProxyCredentials[1] +
          "\n";
      });
    } else {
      copiedProxies.map((obj, index) => {
        allProxiesString += obj?.proxy_url + ":" + obj?.port + "\n";
      });
    }

    copyToClipboard(allProxiesString);

    handleShowSuccess(`${copiedProxies.length} proxies have been copied`);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  // modal

  const [show, setShow] = useState(false);

  const handleClose = () => {
    // setRotateTime(user ? user.proxy_config.rotate : null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // testing...
  const [rotateTime, setRotateTime] = useState(
    user ? user.proxy_config.rotate : null
  );

  const [flag, setFlag] = useState(false);

  let handleChangeRotateTime = (rotate_time) => {
    if (rotate_time < 10) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your rotate time input must be greater than 10!",
      });
    }

    if (rotate_time % 10 != 0) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your rotate time input must be multiple of 10!",
      });
    }

    if (rotate_time >= 5000) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your rotate time input has exceeded the maximum, please re-enter!",
      });
    }

    let data = {
      auth_user: user.proxy_config.auth_user,
      auth_ip: user.proxy_config.auth_ip,
      rotate: Number(rotate_time),
    };

    if (typeof data.rotate === "number") {
      let token = localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null;

      const headers = { Authorization: "Bearer " + token };

      axios
        .put("/api/v1/users/config-proxy", data, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            Swal.fire(
              "Created!",
              `Config rotation time successfully!`,
              "success"
            );
            setFlag((current) => !current);
            setRotateTime(data["rotate"]);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Message: " + `${error?.response?.data?.message}`,
          });
        });
    }

    setShow(false);
    window.location.reload();
  };
  // testing...

  useEffect(() => {
    if (copiedProxies.length == 0) {
    }
  }, [copiedProxies]);

  const handleShowSuccess = (suc_msg) => {
    setShowSuccess(suc_msg);
    setTimeout(() => {
      setShowSuccess("");
    }, 2500);
  };

  // modal

  tableData.forEach((item, index) => {
    item.serial = index + 1;
    item.id = index + 1;
  });

  return (
    <>
      <div className="row">
        {/* SHOW ERROR */}
        {/* {showError && <Alert severity='error'>{showError}</Alert>} */}
        {/* {showSuccess && <Alert severity='success'>{showSuccess}</Alert>} */}
        {showSuccess && <Alert severity="success">{showSuccess}</Alert>}
      </div>
      <div class="row mt-5 px-4">
        <div class="col-12">
          <div class="card mb-4">
            <div className="mt-3 me-2">
              <div className="d-flex align-items-center justify-content-between px-3">
                <div>
                  <span className="text-capitalize fw-bold">total:</span>
                  <span className="fw-bold ms-2">{tableData?.length}</span>
                </div>
                <div
                  className="d-flex align-items-center justify-content-end"
                  style={{ width: "110px" }}
                >
                  <button
                    // disabled={copiedProxies.length > 0 ? "true" : "false"}
                    type="button"
                    onClick={CopyProxies}
                    // disabled={hasCopiedText}
                    className={
                      copiedProxies.length > 0
                        ? "text-white btn bg-success bg-gradient my-0 mb-3 mx-0"
                        : "text-white btn bg-success bg-gradient disabled my-0 mb-3 mx-0"
                    }
                  >
                    Copy
                  </button>
                  {/* <span className="fs-4">({copiedProxies.length})</span> */}
                </div>

                {/* modal */}
                {/* <Modal
                  show={show}
                  onHide={handleClose}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Setting</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Time Rotate ( Minutes )</Form.Label>
                        <Form.Control
                          type="number"
                          value={rotateTime}
                          onChange={(e) => setRotateTime(e.target.value)}
                          placeholder="Example: 10, 20"
                          autoFocus
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => handleChangeRotateTime(rotateTime)}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal> */}

                {/* button show modal */}

                {/* end of modal */}
              </div>
            </div>
            <div className="border-top" style={{ height: 1200, width: "100%" }}>
              {/* Data grid */}
              <StyledDataGrid
                // test
                rows={tableData}
                columns={columns}
                // test
                checkboxSelection
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[PAGE_SIZE]}
                onRowSelectionModelChange={(row_id) => {
                  let temp_arr = [];
                  row_id.map((id) => {
                    temp_arr.push(tableData[id - 1]);
                  });
                  setCopiedProxies(temp_arr);
                }}
                slots={{
                  pagination: CustomPagination,
                }}
                {...tableData}
              />
              {/* end of Data grid */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IpProxyTab;
