import "./App.css";
import Login from "./pages/auth/Login";
import Admin from "./pages/admin/Admin";
import Dashboard from "./pages/customer/dashboard/Dashboard";
import NotFound404 from "./pages/NotFound404";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NavProvider } from "./contexts/NavContext";
import { AuthProvider } from "./contexts/AuthContext";
import UnderMaintenance from "./pages/underMaintenance/UnderMaintenance";
import LineProxyList from "./pages/admin/LineProxyList";
import UserPackages from "./pages/package/UserPackages";
import PackageManagement from "./pages/customer/packageManagement/PackageManagement";
import PackageManagementByReseller from "./pages/reseller/PackageManagementByReseller";
import Reseller from "./pages/reseller/Reseller";
import BuyPackage from "./pages/customer/buyPackage/BuyPackage";
import OrderPackage from "./pages/customer/orderPackage/OrderPackage";
import CustomerSupport from "./pages/customer/customerSupport/CustomerSupport";
import Register from "./pages/auth/Register";
import PackagePurchase from "./pages/customer/packagePurchase/PackagePurchase";
import ProxyManagement from "./pages/customer/proxyManagement/ProxyManagement";
import Contact from "./components/contact/Contact";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <NavProvider>
            <Routes>
              {/* Admin */}
              <Route path="/" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="admin/proxy-management" element={<Admin />} />
              <Route path="admin/user-packages" element={<UserPackages />} />
              <Route
                path="admin/proxy-management/line-proxies-list/:proxy_line_id"
                element={<LineProxyList />}
              />

              <Route path="404-not-found" element={<NotFound404 />} />
              <Route path="under-maintenance" element={<UnderMaintenance />} />
              {/* <Route path="*" element={<Navigate to="404-not-found" />} /> */}
              <Route path="*" element={<NotFound404 />} />

              {/* Reseller role*/}
              <Route path="reseller/user-management" element={<Reseller />} />
              <Route
                path="reseller/package-management"
                element={<PackageManagementByReseller />}
              />

              {/* Customer */}
              {/* <Route path="/user/dashboard" element={<Dashboard />} /> */}
              {/* <Route index element={<Navigate to={"/user/dashboard"} />} /> */}
              <Route
                path="/user/package-purchase"
                element={<PackagePurchase />}
              />
              <Route
                path="/user/proxy-management"
                element={<ProxyManagement />}
              />
              <Route path="proxy-products" element={<BuyPackage />} />
              <Route
                path="proxy-products/order/:info"
                element={<OrderPackage />}
              />
              <Route
                path="user/package-management"
                element={<PackageManagement />}
              />
              <Route path="user/support" element={<CustomerSupport />} />
            </Routes>
          </NavProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
