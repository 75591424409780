import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Sidebar1 from "../../components/sidebar1/Sidebar1";
import Navbar from "../../components/navbar/Navbar";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  StyledDataGrid,
  CustomPagination,
} from "../../components/styledDataGrid/StyledDataGrid";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AuthContext from "../../contexts/AuthContext";
import { useContext } from "react";

const PackageManagementByReseller = () => {
  // contexts
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  // states
  let [token, setToken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [showBuyPackageModal, setShowBuyPackageModal] = useState(false);

  const [packages, setPackages] = useState([]);
  const [resetPackages, setResetPackages] = useState(false);

  const handleShowbuyPackageModal = () => {
    setShowBuyPackageModal(true);
  };

  const handleCloseBuyPackageModal = () => {
    setShowBuyPackageModal(false);
    // set states
  };

  const handleSearchByKeyword = async (e) => {
    e.preventDefault();
    if (token) {
      axios
        .get(
          `/api/v1/admin/user-packages/all?keyword=` +
            e.target.inputFilter.value,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setPackages(res.data.data.items);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (token) {
      axios
        .get(`/api/v1/admin/user-packages/all`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setPackages(res.data.data.items);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [resetPackages]);

  useEffect(() => {
    packages.map((userPackage, index) => {
      userPackage.serial = index + 1;

      // thousand seperator to price
      userPackage.price = userPackage.price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      // thousand seperator to final price
      userPackage.final_price = userPackage.final_price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    });
  }, [packages]);

  const PAGE_SIZE = 20;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const columns = useMemo(() => {
    const result = [
      {
        field: "banking_code",
        headerName: "BANKING CODE",
        width: 160,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center">
              {params.row.confirmed === true && <span>{params.value}</span>}
              {params.row.confirmed === false && (
                <span className="text-danger">{params.value}</span>
              )}
            </div>
          );
        },
      },
      {
        field: "user_obj",
        headerName: "USER",
        width: 150,
        renderCell: (params) => {
          return params.value.email;
        },
      },
      {
        field: "package_name",
        headerName: "PACKAGE NAME",
        width: 140,
        renderCell: (params) => {
          return <span className="text-capitalize">{params.value}</span>;
        },
      },
      {
        field: "package_number",
        headerName: "PACKAGE NUMBER",
        width: 150,
        renderCell: (params) => {
          return <span className="text-capitalize">{params.value}</span>;
        },
      },
      { field: "package_days", headerName: "PACKAGE DAYS", width: 120 },
      // { field: "proxy_number", headerName: "PROXY NUMBERS", width: 130 },
      { field: "proxy_type", headerName: "TYPE", width: 70 },
      {
        field: "confirmed",
        headerName: "CONFIRMED",
        width: 100,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center">
              {params.value === true && (
                <span class="c-pill c-pill--success">True</span>
              )}
              {params.value === false && (
                <span class="c-pill c-pill--danger">False</span>
              )}
            </div>
          );
        },
      },
      { field: "price", headerName: "PRICE (VND)", width: 120 },
      { field: "discount", headerName: "DISCOUNT (%)", width: 125 },
      { field: "final_price", headerName: "FINAL PRICE (VND)", width: 140 },
      { field: "started_at", headerName: "START PACKAGE", width: 200 },
      { field: "expired_at", headerName: "END PACKAGE", width: 200 },
    ];
    return result;
  }, []);

  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                {/* card header */}
                <div className="card-header text-uppercase pb-0">
                  <h5>User Package management</h5>
                  <div className="border-top d-none"></div>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <div>
                      <span className="text-capitalize">Total:</span>
                      <span className="ms-2 text-bold">{packages?.length}</span>
                    </div>

                    {user?.group?.role === "user" && (
                      <Button
                        className="btn-info bg-gradient d-flex py-1 px-2"
                        onClick={handleShowbuyPackageModal}
                      >
                        <LocalMallIcon className="text-warning" />

                        <h6 class="text-sm text-white font-weight-normal mb-1 ms-2">
                          <span class="font-weight-bold text-center">
                            Buy Packages
                          </span>
                        </h6>
                      </Button>
                    )}
                    {user?.group?.role !== "user" && (
                      <div style={{ maxWidth: 200 }}>
                        <Form onSubmit={handleSearchByKeyword}>
                          <Form.Control
                            type="text"
                            id="inputFilter"
                            htmlSize="200"
                            name="inputFilter"
                            placeholder="Filter..."
                          />
                        </Form>
                      </div>
                    )}
                  </div>
                </div>

                {/* card body */}
                <div
                  className="card-body border-top px-0 pt-0 pb-2"
                  style={{ height: 1040, width: "100%" }}
                >
                  <StyledDataGrid
                    //
                    sx={{
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                          display: "none",
                        },
                      "& .css-i4bv87-MuiSvgIcon-root": {
                        display: "none",
                      },
                    }}
                    rowHeight={45}
                    // test
                    // getRowHeight={() => "auto"}
                    rows={packages}
                    columns={columns}
                    // test
                    checkboxSelection
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}
                    slots={{
                      pagination: CustomPagination,
                    }}
                    {...packages}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* MODALS */}
      {/* PACKAGE-CREATE MODAL */}
      <Modal
        dialogClassName="my-modal"
        show={showBuyPackageModal}
        onHide={handleCloseBuyPackageModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 10000 }}
        centered
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-center w-100">
            <Modal.Title className="text-uppercase">
              ROTATING RESIDENTIAL PROXY PACKAGES
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div class="demo mt-5 mb-5">
            <div class="container">
              <div class="row">
                <div class="col-md-3 col-sm-6">
                  <div class="pricingTable">
                    <div class="pricingTable-header">
                      <i class="fa fa-adjust"></i>
                      <div class="price-value">
                        306.000đ
                        <span class="month fw-bolder">/1 month</span>
                      </div>
                      <span class="month">(~30 days)</span>
                    </div>
                    <h3 class="heading">Standard</h3>
                    <div class="pricing-content">
                      <ul>
                        <li>
                          Quantity: <b>100 proxies</b>
                        </li>
                        <li>
                          Bandwidth: <b>Unlimited</b>
                        </li>
                        <li>
                          Discount: <b>15%</b>
                        </li>
                        <li>
                          Original Price: <b>360.000đ</b>
                        </li>
                      </ul>
                    </div>
                    <div class="pricingTable-signup">
                      <Link to="/proxy-products/order/package_days=30&proxy_number=100">
                        Buy Package
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="pricingTable green">
                    <div class="wrap">
                      <span class="ribbon6 text-danger fw-bold">
                        Best Seller
                      </span>
                    </div>
                    <div class="pricingTable-header">
                      <i class="fa fa-briefcase"></i>
                      <div class="price-value">
                        612.000đ
                        <span class="month fw-bold">/2 months</span>
                      </div>
                      <span class="month">(~60 days)</span>
                    </div>
                    <h3 class="heading">Business</h3>
                    <div class="pricing-content">
                      <ul>
                        <li>
                          Quantity: <b>100 proxy</b>
                        </li>
                        <li>
                          Bandwidth: <b>Unlimited</b>
                        </li>
                        <li>
                          Discount: <b>15%</b>
                        </li>
                        <li>
                          Original Price: <b>720.000đ</b>
                        </li>
                      </ul>
                    </div>
                    <div class="pricingTable-signup">
                      <Link to="/proxy-products/order/package_days=60&proxy_number=100">
                        Buy Package
                      </Link>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="pricingTable blue">
                    <div class="pricingTable-header">
                      <i class="fa fa-diamond"></i>
                      <div class="price-value">
                        918.000đ <span class="month">/3 months</span>
                      </div>
                      <span className="month">(~90 days)</span>
                    </div>
                    <h3 class="heading">Premium</h3>
                    <div class="pricing-content">
                      <ul>
                        <li>
                          Quantity: <b>100 proxy</b>
                        </li>
                        <li>
                          Bandwidth: <b>Unlimited</b>
                        </li>
                        <li>
                          Discount: <b>15%</b>
                        </li>
                        <li>
                          Original Price: <b>1.080.000đ</b>
                        </li>
                      </ul>
                    </div>

                    <div class="pricingTable-signup">
                      <Link to="/proxy-products/order/package_days=90&proxy_number=100">
                        Buy Package
                      </Link>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="pricingTable red">
                    <div class="pricingTable-header">
                      <i class="fa fa-cube"></i>
                      <div class="price-value">
                        3.672.000đ <span class="month">/1 year</span>
                      </div>
                      <span className="month">(~360 days)</span>
                    </div>
                    <h3 class="heading">Extra</h3>
                    <div class="pricing-content">
                      <ul>
                        <li>
                          Quantity: <b>100 proxy</b>
                        </li>
                        <li>
                          Bandwidth: <b>Unlimited</b>
                        </li>
                        <li>
                          Discount: <b>15%</b>
                        </li>
                        <li>
                          Original Price: <b>4.320.000đ</b>
                        </li>
                      </ul>
                    </div>
                    <div class="pricingTable-signup">
                      <Link to="/proxy-products/order/package_days=360&proxy_number=100">
                        Buy Package
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* test */}
          {/* <div class="ribbon"> */}
          {/* <div class="wrap">
            <span class="ribbon6 text-bold text-danger">Giá siêu tốt</span>
          </div> */}
          {/* </div> */}
          {/* test */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBuyPackageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END OF PACKAGE-CREATE MODAL */}
      {/* END OF MODALS */}
    </React.Fragment>
  );
};

export default PackageManagementByReseller;
