import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Sidebar1 from "../../../components/sidebar1/Sidebar1";
import Navbar from "../../../components/navbar/Navbar";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import {
  StyledDataGrid,
  CustomPagination,
} from "../../../components/styledDataGrid/StyledDataGrid";
import NavContext from "../../../contexts/NavContext";
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { numberWithCommas } from "../../../util/util";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import Socks5Tab from "../../../components/socks5Tab/Socks5Tab";
import AuthenTab from "../../../components/authenTab/AuthenTab";
import IpProxyTab from "../../../components/ipProxyTab/IpProxyTab";
import NavTab from "../../../components/navTab/NavTab";

const ProxyManagement = () => {
  // contexts
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  let [token, setToken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const navContext = useContext(NavContext);
  const {
    ipProxyTabToggle,
    setIpProxyToggle,
    socks5TabToggle,
    setSocks5Toggle,
    authTabToggle,
    setAuthTabToggle,
  } = navContext;

  const [showProxyLineListModal, setShowProxyLineListModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [packages, setPackages] = useState([]);
  const [currentRow, setCurrentRow] = useState(0);
  const [updateDataFlag, setUpdateDataFlag] = useState(false);

  const handleShowLineListModal = (row_index) => {
    setCurrentRow(row_index - 1);
    setShowProxyLineListModal(true);
  };

  const handleCloseLineListModal = () => {
    setShowProxyLineListModal(false);
  };

  let ListUserProxies = async (token) => {
    const headers = { Authorization: "Bearer " + token };
    axios
      .get("/api/v1/user-proxies", { headers: headers })
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data.items;
          const itemsWithIds = data.map((item, index) => {
            return { ...item, number: index + 1 };
          });
          setTableData(itemsWithIds);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    let token = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;
    ListUserProxies(token);
  }, []);

  const PAGE_SIZE = 20;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const columns = useMemo(() => {
    const result = [
      // { field: "id", headerName: "ID", width: 80 },
      { field: "proxy_url", headerName: "Url", width: 300 },
      {
        field: "proxy_type",
        headerName: "Type",
        width: 200,
      },
      { field: "proxy_version", headerName: "Version", width: 200 },
      { field: "started_at", headerName: "Started At", width: 300 },
      { field: "expired_at", headerName: "Expired At", width: 300 },
      {
        field: "actions",
        headerName: "Action",
        width: 200,
        renderCell: (params) => {
          return (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div class="btn-group">
                <Button
                  variant="outline-info"
                  size="sm"
                  className="m-0 px-0 px-3"
                  onClick={() => handleShowLineListModal(params.row.number)}
                >
                  Detail
                </Button>
                {/* <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover
                      id="popover-positioned-bottom"
                      title="Proxy list action"
                    >
                      <div className="px-2 py-2" role="button">
                        <div
                          className="d-flex align-items-center px-1 py-1 rounded popover-content"
                          onClick={() =>
                            handleShowLineListModal(params.row.number)
                          }
                        >
                          <DisplaySettingsIcon className="text-secondary" />

                          <span className="ms-4 fw-bold text-secondary">
                            Show
                          </span>
                        </div>
                      </div>
                    </Popover>
                  }
                >
                  <button
                    type="button"
                    className="rounded-circle border bg-light px-1 py-1"
                  >
                    <MoreHorizIcon style={{ display: "block" }} />
                  </button>
                </OverlayTrigger> */}
              </div>
            </div>
          );
        },
      },
    ];
    return result;
  }, []);

  useEffect(() => {
    if (updateDataFlag == true) {
      let token = localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null;
      ListUserProxies(token);
      setUpdateDataFlag(false);
    }
  }, [updateDataFlag]);

  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                {/* card header */}
                <div className="card-header text-uppercase pb-0">
                  <h5>Your Proxies</h5>
                  <div className="border-top d-none"></div>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <div>
                      <span className="text-capitalize">Total:</span>
                      <span className="ms-2 text-bold">{tableData.length}</span>
                    </div>
                  </div>
                </div>

                {/* card body */}
                <div
                  className="card-body border-top px-0 pt-0 pb-2"
                  style={{ height: 690, width: "100%" }}
                >
                  <StyledDataGrid
                    //
                    sx={{
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                          display: "none",
                        },
                      "& .css-i4bv87-MuiSvgIcon-root": {
                        display: "none",
                      },
                    }}
                    rowHeight={45}
                    // test
                    // getRowHeight={() => "auto"}
                    rows={tableData}
                    columns={columns}
                    // test
                    checkboxSelection
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}
                    slots={{
                      pagination: CustomPagination,
                    }}
                    {...tableData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* MODALS */}
      {/* PACKAGE-CREATE MODAL */}
      <Modal
        dialogClassName="my-modal"
        show={showProxyLineListModal}
        onHide={handleCloseLineListModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 10000 }}
        centered
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-center w-100">
            <Modal.Title className="text-uppercase">Proxy detail</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <main class="main-content position-relative border-radius-lg ">
            <NavTab />

            {/* table paginations */}

            {/* toggle tab IP Proxy */}
            {ipProxyTabToggle && (
              <IpProxyTab
                proxies={tableData?.[currentRow]?.["proxies"]}
                proxyType={tableData?.[currentRow]?.["proxy_type"]}
                proxyConfig={tableData?.[currentRow]?.["proxy_config"]}
              />
            )}
            {/* toggle tab Socks5 */}
            {socks5TabToggle && <Socks5Tab />}
            {/* toggle tab Authentication */}
            {authTabToggle && (
              <AuthenTab
                packageId={tableData?.[currentRow]?.["id"]}
                proxyType={tableData?.[currentRow]?.["proxy_type"]}
                proxyConfig={tableData?.[currentRow]?.["proxy_config"]}
                setUpdateDataFlag={setUpdateDataFlag}
                updateDataFlag={updateDataFlag}
              />
            )}

            {/* table pagintaions */}
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLineListModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END OF PACKAGE-CREATE MODAL */}
      {/* END OF MODALS */}
    </React.Fragment>
  );
};

export default ProxyManagement;
