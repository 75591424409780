import React, { useState, useEffect } from "react";
import "./orderPackage.css";
import axios from "axios";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar1 from "../../../components/sidebar1/Sidebar1";
import Navbar from "../../../components/navbar/Navbar";
import { nth } from "../../../util/util";
import AuthContext from "../../../contexts/AuthContext";
import { useContext } from "react";

const date = new Date();

const OrderPackage = () => {
  const authContext = useContext(AuthContext);

  const { user } = authContext;

  let { info } = useParams();
  const [paymentInfo, setPaymentInfo] = useState({});

  const navigate = useNavigate();

  //   states
  const [form, setForm] = useState({
    packageNumbers: Number(info.split("&")[0].split("=")[1]),
    proxyType: info.split("&")[1].split("=")[1],
    packageName: info.split("&")[2].split("=")[1],
    packageDays: 30,
    proxyVersion: "ip6",
    // user_id: user?.id,
  });

  useEffect(() => {
    console.table(form);
  }, [form]);

  const [selectOptions, setSelectOptions] = useState([
    {
      value: "15",
      text: "0.5 Month (~15 days)",
    },
    {
      value: "30",
      text: "1 Month (~30 days)",
    },
    {
      value: "60",
      text: "2 Months (~60 days)",
    },
    {
      value: "90",
      text: "3 Months (~90 days)",
    },
    {
      value: "120",
      text: "4 Months (~120 days)",
    },
    {
      value: "150",
      text: "5 Months (~150 days)",
    },
    {
      value: "180",
      text: "6 Months (~180 days)",
    },
    {
      value: "210",
      text: "7 Months (~210 days)",
    },
    {
      value: "240",
      text: "8 Months (~240 days)",
    },
    {
      value: "270",
      text: "9 Months (~270 days)",
    },
    {
      value: "300",
      text: "10 Months (~310 days)",
    },
    {
      value: "330",
      text: "11 Months (~340 days)",
    },
    {
      value: "360",
      text: "12 Months (~360 days)",
    },
  ]);

  const [preOrderPackage, setPreOrderPackage] = useState({
    discount: 0,
    price: 0,
    finalPrice: 0,
    trial: false,
  });

  const [trialSecretCount, setTrialSecretCount] = useState(0);

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("Text copied : " + text);
  };

  const handleOrderPackage = (e) => {
    e.preventDefault();

    // console.log("================================================");
    // console.table(form);
    // console.log("================================================");

    // if (!form.packageDays) {
    //   return Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Package days cannot be empty!",
    //   });
    // } else {
    //   if (
    //     Number(form.packageDays) % 15 !== 0 &&
    //     Number(form.packageDays) !== 3
    //   ) {
    //     return Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "Package days must be divisible by 15!",
    //     });
    //   }
    // }

    if (!form.packageNumbers) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Proxy numbers cannot be empty!",
      });
    } else {
      if (Number(form.packageNumbers) < 1) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The minimum of proxy number is 1!",
        });
      }
    }

    let data = {
      user_id: user?.id,
      package_days: Number(form.packageDays),
      package_number: Number(form.packageNumbers),
      proxy_type: form.proxyType,
      proxy_version: "ip6",
      package_name: form.packageName,
    };

    axios
      .post("/api/v1/user-packages", data)
      .then((res) => {
        if (res.status === 200) {
          const cost = res?.data?.data?.final_price;
          const value = res?.data?.data?.banking_code;
          Swal.fire({
            html: `
                <h3 className="modal-title" id="exampleModalLabel">
                  Thanh toán đơn hàng
                </h3>
                <hr color="gray" width="100%" size="3px" align="center" />
                <div class="css-onl3ck">
                  <div class="css-k3gz8i">
                    <div class="css-zd4of5">
                      <p class="chakra-text css-1a824c8">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                        focusable="false" class="chakra-icon css-191mp66" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                          <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                          </path>
                        </svg>
                        Đăng nhập vào
                        <strong>
                          Internet Banking
                        </strong>
                        ( ngân hàng điện tử )
                      </p>
                      <p class="chakra-text css-1ad58zi">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                        focusable="false" class="chakra-icon css-191mp66" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                          <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                          </path>
                        </svg>
                        Tạo giao dịch chuyển tiền tới tài khoản sau
                      </p>
                      <div style='background-color:#eee;padding:16px;text-align:left'>
                        <table>
                          <tr>
                            <td>Ngân hàng :</td>
                            <td>${paymentInfo.bank_name}</td>
                          </tr>
                          <tr>
                            <td>Tên chủ tài khoản :</td>
                            <td><strong>${paymentInfo.account_name}</strong></td>
                          </tr>
                          <tr>
                            <td>Số tài khoản :</td>
                            <td id='stk_banking' class='copyInfoBank'>
                              <strong>${paymentInfo.account_number}</strong>
                              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeXl2 css-1x60hrx" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ContentCopyIcon">
                                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                                </path>
                              </svg>
                            </td>
                          </tr>
                          <tr>
                            <td style='text-align:left'>Nội dung chuyển khoản :</td>
                            <td id='banking_code' class='copyInfoBank'>
                            <strong>${value}</strong>
                              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeXl2 css-1x60hrx" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ContentCopyIcon">
                                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                                </path>
                              </svg>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <p class="chakra-text css-1dzhzym" style='text-align:left'>
                        <strong>
                          ** Chú ý: Gói cước sẽ được nâng cấp sau khi khách hàng thanh toán thành công.
                        </strong>
                      </p>
                      <p class="chakra-text css-mj29dz" style='text-align:left'>
                        <strong>
                          ** Chuyển khoản thiếu không được nâng cấp, chuyển khoản thừa bị mất 10% phí xử lý
                        </strong>
                      </p>
                      <p class="chakra-text css-mj29dz" style='text-align:left'>
                        <strong>
                          ** Các giao dịch quá 15 ngày không báo kiểm tra sẽ không được xử lý
                        </strong>
                      </p>
                    </div>
                    <div class="css-zd4of5">
                      <p class="chakra-text css-1ad58zi">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                        focusable="false" class="chakra-icon css-191mp66" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                          <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                          </path>
                        </svg>
                        Hoặc quét
                        <strong>
                          Mã QR
                        </strong>
                      </p>
                      <div class="chakra-card css-1qhukjj"> 
                      <img src="https://img.vietqr.io/image/${paymentInfo.bank_code}-${paymentInfo.account_number}-compact2.png?amount=${cost}&addInfo=${value}&accountName=${paymentInfo.account_name}"
                         class="chakra-image css-ab50tw">
                      </div>
                      
                    </div>
                  </div>
                </div>
                `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: {
              popup: "custom-popup", // Thêm lớp CSS tùy chỉnh cho thông báo
            },
            didOpen: () => {
              // Thêm sự kiện click cho từng phần tử có class "clickable-item"
              const clickableItems = document.querySelectorAll(".copyInfoBank");
              clickableItems.forEach((item) => {
                item.addEventListener("click", () => {
                  // Sao chép nội dung vào clipboard khi phần tử được click
                  copyToClipboard(item.innerText);
                });
              });
            },
          });

          navigate("/user/package-management");
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response.data.error_code === 5006) {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your account already has a trial package, each account is limited to only 1 trial package.",
          });
        } else {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Message: " + `${err?.response?.data?.message}`,
          });
        }
      });
  };

  const handlePreOrderPackage = () => {
    if (form.packageNumbers <= 0) {
      setPreOrderPackage((prev) => {
        return { ...prev, price: 0, finalPrice: 0 };
      });

      return undefined;
    }
    let data = {
      user_id: user?.id,
      package_days: Number(form.packageDays),
      package_number: Number(form.packageNumbers),
      proxy_type: form.proxyType,
      proxy_version: form.proxyVersion,
      package_name: form.packageName,
    };

    axios
      .post("/api/v1/user-packages/pre-order", data)
      .then((res) => {
        if (res.status === 200) {
          setPreOrderPackage({
            type: res.data.data.proxy_type,
            version: res.data.data.proxy_version,
            discount: res.data.data.discount,
            price: res.data.data.price,
            finalPrice: res.data.data.final_price,
            trial: res.data.data.trial,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    console.table(preOrderPackage);
  }, [preOrderPackage]);

  const handleChangeSecretCount = (e) => {
    e.preventDefault();
    setTrialSecretCount((prevCount) => prevCount + 1);
  };

  const handleShowTrial = () => {
    setSelectOptions([
      {
        value: "3",
        text: "3 days (Free Trial)",
      },
    ]);
    setForm({
      packageDays: 3,
      proxyNumbers: 5,
      packageName: "trial",
    });
  };

  useEffect(() => {
    if (trialSecretCount == 10) {
      handleShowTrial();
    }
  }, [trialSecretCount]);

  useEffect(() => {
    handlePreOrderPackage();
  }, [form]);

  useEffect(() => {
    axios
      .get(`/api/v1/users/company`, {})
      .then((res) => {
        if (res.status === 200) {
          setPaymentInfo(res?.data?.bank_info);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                {/* card header */}
                <div className="card-header text-center text-uppercase pb-0 mt-2 mb-4">
                  <div className="bg-light rounded py-3">
                    <h4 className="">SET UP THE ORDER (PROXY IPV6)</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    {/* order infomation */}
                    <div className="col w-100 px-0 border">
                      <div
                        class="d-flex flex-column justify-content-center align-items-center w-100"
                        id="order-heading"
                      >
                        <div class="text-uppercase">
                          <p>order details</p>
                        </div>
                        <div class="h4">
                          {" "}
                          {date.toLocaleString("default", {
                            month: "long",
                          })}
                          , {date.getDate()}
                          {nth(date.getDate())}, {date.getFullYear()}
                          {/* Ngày {date.getDate()}, Tháng {date.getMonth() + 1},
                          năm {date.getFullYear()} */}
                        </div>
                        <div class="pt-1">
                          <p>
                            (You can customize the package numbers and proxy
                            type in the below form)
                          </p>
                        </div>
                      </div>
                      <div class="wrapper bg-white mt-4 px-0 px-2">
                        <div className="row">
                          <div className="col-2">
                            <span style={{ fontSize: "1rem" }}>Package</span>
                          </div>

                          {/* <div className="col-2">
                            <span style={{ fontSize: "1rem" }}>
                              Package Days
                            </span>
                          </div> */}

                          <div className="col-2">
                            <span style={{ fontSize: "1rem" }}>Type</span>
                          </div>

                          <div className="col-2">
                            <span style={{ fontSize: "1rem" }}>Version</span>
                          </div>

                          <div className="col-2">
                            <span style={{ fontSize: "1rem" }}>
                              Original Price
                            </span>
                          </div>
                          <div className="col-2">
                            <span style={{ fontSize: "1rem" }}>Discount</span>
                          </div>
                          <div className="col-2">
                            <span style={{ fontSize: "1rem" }}>
                              Final Price
                            </span>
                          </div>
                        </div>
                        <div className="row border-top pt-2 mt-2">
                          <div className="col-2">
                            <span className="text-primary fw-bold text-uppercase">
                              {form.packageName}
                            </span>
                          </div>
                          <div className="col-2">
                            <span className="text-primary fw-bold">
                              {preOrderPackage.type}
                            </span>
                          </div>

                          <div className="col-2">
                            <span className="text-primary fw-bold">
                              {preOrderPackage.version}
                            </span>
                          </div>

                          <div className="col-2">
                            {preOrderPackage.trial ? (
                              <span className="text-primary">Free Trial</span>
                            ) : (
                              <span className="text-primary fw-bold">
                                {preOrderPackage.price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                                đ
                              </span>
                            )}
                          </div>
                          <div className="col-2">
                            <span className="text-info fw-bold">
                              {preOrderPackage.discount} %
                            </span>
                          </div>
                          <div className="col-2">
                            {preOrderPackage.trial ? (
                              <span className="text-danger">Free Trial</span>
                            ) : (
                              <b className="text-danger">
                                {preOrderPackage.finalPrice
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                                đ
                              </b>
                            )}
                          </div>
                        </div>
                        {/* form */}
                        <div className="mt-3 border-top pt-4">
                          <form>
                            {/* <!-- 2 column grid layout with text inputs for the first and last names --> */}

                            <div class="row mb-4">
                              <div class="col">
                                <div class="form-outline">
                                  <label class="form-label" for="form6Example2">
                                    <button
                                      type="button"
                                      className="border-0 rounded px-2 py-1"
                                      onClick={(e) =>
                                        handleChangeSecretCount(e)
                                      }
                                    >
                                      <span className="fs-6 fw-bold">
                                        Your email
                                      </span>
                                    </button>
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    id="form6Example2"
                                    class="form-control"
                                    value={user.email}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <div class="form-outline mb-4">
                              <label class="form-label" for="form6Example3">
                                <span className="fs-6">Package days</span>
                                <span
                                  className="fw-normal ms-1"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  (must be divisible by 30)
                                </span>
                              </label>
                              <Form.Select
                                aria-label="Default select example"
                                name="packageDays"
                                value={form.packageDays}
                                onChange={handleFormInputChange}
                              >
                                {selectOptions.map((selectOption, index) => (
                                  <option
                                    key={index}
                                    value={selectOption.value}
                                  >
                                    {selectOption.text}
                                  </option>
                                ))}
                                
                              </Form.Select>
                            </div> */}

                            <div class="form-outline mb-4">
                              <label class="form-label" for="form6Example3">
                                <span className="fs-6">Package numbers</span>
                                <span
                                  className="fw-normal ms-1"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  (minimum 1 package)
                                </span>
                              </label>
                              <input
                                disabled={preOrderPackage.trial ? true : false}
                                type="number"
                                id="form6Example3"
                                name="packageNumbers"
                                class="form-control"
                                min="1"
                                value={form.packageNumbers}
                                onChange={handleFormInputChange}
                              />
                            </div>

                            <div class="form-outline mb-4">
                              <label class="form-label" for="form6Example3">
                                <span className="fs-6">Proxy type</span>
                                <span
                                  className="fw-normal ms-1"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  (HTTP or Sock5)
                                </span>
                              </label>
                              <Form.Select
                                aria-label="Default select example"
                                name="proxyType"
                                value={form.proxyType}
                                onChange={handleFormInputChange}
                              >
                                <option value="http">http</option>
                                <option value="sock">sock</option>
                              </Form.Select>
                            </div>

                            {/* <div class="form-outline mb-4">
                              <label class="form-label" for="form6Example3">
                                <span className="fs-6">Proxy numbers</span>
                                <span
                                  className="fw-normal ms-1"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  (minimum 1 proxy)
                                </span>
                              </label>
                              <input
                                disabled={preOrderPackage.trial ? true : false}
                                type="number"
                                id="form6Example3"
                                name="proxyNumbers"
                                class="form-control"
                                min="10"
                                value={form.proxyNumbers}
                                onChange={handleFormInputChange}
                              />
                            </div> */}
                          </form>
                        </div>
                        <div class="pt-2 border-bottom mb-3"></div>
                        {/* <div class="d-flex justify-content-start align-items-center pl-3">
                          <div class="text-muted">Phương thức thanh toán:</div>
                          <div class="ml-auto">
                            <label>Chuyển khoản</label>{" "}
                          </div>
                        </div> */}

                        {/* <div class="d-flex justify-content-start align-items-center pb-4 pl-3 border-bottom">
                          <div class="text-muted">
                            {" "}
                            <button class="text-white btn">
                              50% Discount
                            </button>{" "}
                          </div>
                          <div class="ml-auto price"> -$34.94 </div>
                        </div> */}

                        {/* <!-- Submit button --> */}
                        <div className="d-flex justify-content-center">
                          <button
                            class="btn btn-primary btn-block mb-4"
                            onClick={handleOrderPackage}
                          >
                            Order Package
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default OrderPackage;
