import React, { useState, useEffect } from "react";
import { useContext } from "react";
import NavContext from "../../contexts/NavContext";

const NavTab = () => {
  // context states
  const navContext = useContext(NavContext);
  const {
    ipProxyTabToggle,
    setIpProxyToggle,
    socks5TabToggle,
    setSocks5Toggle,
    authTabToggle,
    setAuthTabToggle,
  } = navContext;
  // context states

  return (
    <>
      <div class="card shadow-lg mx-4 card-profile-top">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-lg-12 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
              <div class="nav-wrapper position-relative end-0">
                <ul class="nav nav-pills nav-fill p-1" role="tablist">
                  <li class="nav-item">
                    <a
                      className={
                        ipProxyTabToggle
                          ? "nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center bg-info text-white"
                          : "nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center"
                      }
                      data-bs-toggle="tab"
                      //   href="javascript:;"
                      role="tab"
                      aria-selected="true"
                      type="button"
                      onClick={() => {
                        setIpProxyToggle(true);
                        setSocks5Toggle(false);
                        setAuthTabToggle(false);
                      }}>
                      <i class="ni ni-app"></i>
                      <span class="ms-2">IP PROXY</span>
                    </a>
                  </li>
                  {/* Socks5 is here */}
                  {/* <li class="nav-item">
                    <a
                      className={
                        socks5TabToggle
                          ? "nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center bg-info text-white"
                          : "nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center "
                      }
                      type="button"
                      data-bs-toggle="tab"
                      //   href="javascript:;"
                      role="tab"
                      aria-selected="false"
                      onClick={() => {
                        setIpProxyToggle(false);
                        setSocks5Toggle(true);
                        setAuthTabToggle(false);
                      }}>
                      <i class="ni ni-email-83"></i>
                      <span class="ms-2">SOCKS5</span>
                    </a>
                  </li> */}
                  <li class="nav-item">
                    <a
                      className={
                        authTabToggle
                          ? "nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center bg-info text-white"
                          : "nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center"
                      }
                      type="button"
                      data-bs-toggle="tab"
                      //   href="javascript:;"
                      role="tab"
                      aria-selected="false"
                      onClick={() => {
                        setIpProxyToggle(false);
                        setSocks5Toggle(false);
                        setAuthTabToggle(true);
                      }}>
                      <i class="ni ni-settings-gear-65"></i>
                      <span class="ms-2">AUTHENTICATION</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavTab;
