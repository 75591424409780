import React, { useEffect, useState } from "react";
import Sidebar1 from "../../components/sidebar1/Sidebar1";
import Navbar from "../../components/navbar/Navbar";
import { useContext, useMemo } from "react";
import AuthContext from "../../contexts/AuthContext";
import { Navigate } from "react-router-dom";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Button from "react-bootstrap/Button";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import axios from "axios";
import { CircularProgress } from "@mui/material";

// using for data-grid
function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  ...customCheckbox(theme),
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const PAGE_SIZE = 20;

// "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"

function isValidPassword(password) {
  // var regex = new RegExp(
  //   "^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$"
  // );
  var regex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  return regex.test(password);
}

const Reseller = () => {
  let [token, setToken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  // contexts
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  // states
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  const [showUserCreateModal, setShowUserCreateModal] = useState(false);
  const [loadingUserCreate, setLoadingUserCreate] = useState(false);
  const [registerForm, setRegisterForm] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    referer_id: user.id,
  });
  const [userList, setUserList] = useState([]);
  const [getUserListFlag, setGetUserListFlag] = useState(true);
  const [userManagementToggle, setUserManagementToggle] = useState(true);

  // register functions
  const handleRegisterInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterForm((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleRegisterSubmit = (event) => {
    if (!registerForm.fullname) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "User fullname cannot be empty!",
      });
    }

    if (!registerForm.email) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "User email cannot be empty!",
      });
    } else {
      if (!/\S+@\S+\.\S+/.test(registerForm.email)) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "User email is not valid!",
        });
      }
    }

    if (!registerForm.password) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "User password cannot be empty!",
      });
    } else {
      if (!isValidPassword(registerForm.password)) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Password is not valid!" +
            " It must have a minimum of eight characters, at least one letter, one number and one special character.",
        });
      }
    }

    if (registerForm.confirmPassword != registerForm.password) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password and confirm password did not match, please re-enter!",
      });
    }

    // if form is valid:
    // logic here

    let data = {
      fullname: registerForm.fullname,
      email: registerForm.email,
      password: registerForm.password,
      // user.id is reseller's id
      referer_id: user.id,
    };

    axios
      .post("/api/v1/users/register", data)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire("Created!", `A new user has been created.`, "success");
        }
        setGetUserListFlag((prev) => !prev);
        setShowUserCreateModal(false);
      })
      .catch((err) => {
        console.error("Registration failed!", err);
        setShowUserCreateModal(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Message: " + `${err?.response?.data?.message}`,
        });
      });
  };

  const handleCloseUserCreateModal = () => {
    setShowUserCreateModal(false);
    // reset state
    setRegisterForm({});
  };
  const handleShowUserCreateModal = () => setShowUserCreateModal(true);
  // end of register functions

  // user list functions
  const getUserList = () => {
    const headers = { Authorization: "Bearer " + token };
    axios
      .get("/api/v1/admin/users/list", { headers })
      .then((res) => {
        if (res.status === 200) {
          setUserList(res.data.data.users);
        }
      })
      .catch((err) => {
        console.error("Something went wrong!", err);
      });
  };

  useEffect(() => {
    getUserList();
  }, [getUserListFlag]);

  // end of user list functions

  const columns = useMemo(() => {
    const result = [
      // { field: "serial", headerName: "ID", width: 60 },
      // { field: "id", headerName: "USER ID", width: 363 },
      { field: "email", headerName: "EMAIL", width: 363 },
      { field: "fullname", headerName: "FULLNAME", width: 363 },
      { field: "created_at", headerName: "CREATED AT", width: 363 },
    ];
    return result;
  }, []);

  if (!user) {
    return <Navigate to={"/login"} replace="true" />;
  } else {
    if (user?.group?.role === "user") {
      return <Navigate to={"/user/dashboard"} replace="true" />;
    } else if (
      user?.group?.role === "admin" ||
      user?.group?.role === "superadmin"
    ) {
      return <Navigate to={"/admin/proxy-management"} replace="true" />;
    } else if (user?.group?.role === "reseller") {
      return (
        <React.Fragment>
          <Sidebar1 />
          <main className="main-content position-relative border-radius-lg ">
            <Navbar />

            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-12">
                  <div className="card mb-4">
                    <div className="card-header text-uppercase pb-0">
                      <h6>User list</h6>
                      {/* buttons */}
                      <div className="border-top d-flex align-items-center justify-content-between">
                        <div>
                          <span className="text-capitalize">Total:</span>
                          <span className="ms-2 text-bold">
                            {userList?.length}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={handleShowUserCreateModal}
                          className=" my-2 border-0 badge badge-sm bg-gradient-success d-flex align-items-center">
                          <ControlPointIcon className="fs-4" />{" "}
                          <span className="ms-1">Create user</span>
                        </button>
                      </div>
                    </div>

                    <div className="card-body px-0 pt-0 pb-2">
                      <div
                        className="border-top"
                        style={{ height: 690, width: "100%" }}>
                        <StyledDataGrid
                          // test
                          sx={{
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                              {
                                display: "none",
                              },
                            "& .css-i4bv87-MuiSvgIcon-root": {
                              display: "none",
                            },
                          }}
                          disableRowSelectionOnClick
                          rowHeight={39}
                          rows={userList}
                          columns={columns}
                          checkboxSelection
                          paginationModel={paginationModel}
                          onPaginationModelChange={setPaginationModel}
                          // pageSizeOptions={[PAGE_SIZE]}
                          slots={{
                            pagination: CustomPagination,
                          }}
                          {...userList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          {/* Create a new user modal */}
          <Modal
            show={showUserCreateModal}
            onHide={handleCloseUserCreateModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title className="d-flex align-items-center">
                Create New User
                <span className="fs-6 ms-2 mt-1">
                  (This user will be managed by you)
                </span>
                {/* {loadingAddProxy && <CircularProgress size="1rem" />} */}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form autoComplete="new-password">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1">
                  <Form.Label>Fullname</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullname"
                    value={registerForm.fullname}
                    onChange={handleRegisterInputChange}
                    placeholder="Enter fullname"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    name="email"
                    value={registerForm.email}
                    onChange={handleRegisterInputChange}
                    placeholder="Enter email"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={registerForm.password}
                    onChange={handleRegisterInputChange}
                    placeholder="Enter password"
                  />
                  <span style={{ fontSize: "0.699rem" }} className="ms-1">
                    (minimum eight characters, at least one letter, one number
                    and one special character)
                  </span>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={registerForm.confirmPassword}
                    onChange={handleRegisterInputChange}
                    placeholder="Enter confirm password"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseUserCreateModal}>
                Close
              </Button>

              {!loadingUserCreate ? (
                <Button variant="success" onClick={handleRegisterSubmit}>
                  Create
                </Button>
              ) : (
                <Button disabled variant="success">
                  <CircularProgress size="1.1rem" className="mx-3" />
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  }
};

export default Reseller;
