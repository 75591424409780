import React, { useEffect, useState } from "react";
import Sidebar1 from "../../../components/sidebar1/Sidebar1";
import Navbar from "../../../components/navbar/Navbar";
import FacebookIcon from "@mui/icons-material/Facebook";
import {
  MailOutline,
  Telegram,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Contact from "../../../components/contact/Contact";
import facebookPageBackground from "../../../assets/img/facebook-page.jpg";
import axios from "axios";

const CustomerSupport = () => {
  const [supportInfo, setSupportInfo] = useState({});

  useEffect(() => {
    axios
      .get(`/api/v1/users/company`, {})
      .then((res) => {
        if (res.status === 200) {
          setSupportInfo(res.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                {/* card header */}
                <div className="card-header text-uppercase pb-0">
                  <h5>
                    <i className="ni ni-chat-round text-secondary text-sm opacity-10"></i>{" "}
                    Support
                  </h5>
                  <div className="border-top d-none"></div>
                </div>
              </div>
            </div>
            <div className="col-12">
              {/* FACEBOOK */}
              {supportInfo?.facebook != "" && (
                <div className="card mb-4">
                  {/* card header */}
                  <div className="card-header pb-0">
                    <h6>Direct Message:</h6>
                    <div className="border-top d-none"></div>
                    <div className="mt-4 mb-5">
                      <span>
                        <FacebookIcon className="fs-2" />:{" "}
                        <Link
                          className="fs-5"
                          target="_blank"
                          to={supportInfo?.facebook}
                        >
                          {supportInfo?.facebook}
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* EMAIL ADDRESS */}
              {supportInfo?.email != "" && (
                <div className="card mb-4">
                  {/* card header */}
                  <div className="card-header pb-0">
                    <h6>Email:</h6>
                    <div className="border-top d-none"></div>
                    <div className="mt-4 mb-5">
                      <span>
                        <MailOutline className="fs-2" />:{" "}
                        <span className="fs-5">{supportInfo?.email}</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* PHONE */}
              {supportInfo?.phone != "" && (
                <div className="card mb-4">
                  {/* card header */}
                  <div className="card-header pb-0">
                    <h6>Phone:</h6>
                    <div className="border-top d-none"></div>
                    <div className="mt-4 mb-5">
                      <span>
                        <PhoneAndroidOutlined className="fs-2" />:{" "}
                        <span className="fs-5">{supportInfo?.phone}</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* TELEGRAM */}
              {supportInfo?.telegram != "" && (
                <div className="card mb-4">
                  {/* card header */}
                  <div className="card-header pb-0">
                    <h6>Telegram:</h6>
                    <div className="border-top d-none"></div>
                    <div className="mt-4 mb-5">
                      <span>
                        <Telegram className="fs-2" />:{" "}
                        <span className="fs-5">{supportInfo?.telegram}</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="col-12">
              <div className="card mb-4">
                <div className="card-header pb-0 border">
                  <img src={facebookPageBackground} alt="" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </main>
      {/* <Contact /> */}
    </React.Fragment>
  );
};

export default CustomerSupport;
