import React, { useState, useEffect } from "react";
import Sidebar1 from "../../../components/sidebar1/Sidebar1";
import Navbar from "../../../components/navbar/Navbar";
import NavTab from "../../../components/navTab/NavTab";
import IpProxyTab from "../../../components/ipProxyTab/IpProxyTab";
import { useContext } from "react";
import NavContext from "../../../contexts/NavContext";
import AuthContext from "../../../contexts/AuthContext";
import { Navigate } from "react-router-dom";
// new
import { useDemoData } from "@mui/x-data-grid-generator";

import Socks5Tab from "../../../components/socks5Tab/Socks5Tab";
import AuthenTab from "../../../components/authenTab/AuthenTab";
// new

// new
function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const PAGE_SIZE = 5;
// new

const Dashboard = () => {
  // context
  const navContext = useContext(NavContext);
  const {
    ipProxyTabToggle,
    setIpProxyToggle,
    socks5TabToggle,
    setSocks5Toggle,
    authTabToggle,
    setAuthTabToggle,
  } = navContext;

  const authContext = useContext(AuthContext);
  const { user, setUser, getProfile, authTokens } = authContext;

  // if not authenticated then protect dashboard route
  if (!user) {
    return <Navigate to={"/login"} replace="true" />;
  } else {
    if (user?.group?.role === "admin" || user?.group?.role === "superadmin") {
      return <Navigate to={"/admin/proxy-management"} replace="true" />;
    } else if (user?.group?.role === "reseller") {
      return <Navigate to="/reseller/user-management" replace="true" />;
    } else if (user?.group?.role === "user") {
      return (
        <React.Fragment>
          <Sidebar1 />

          <main class="main-content position-relative border-radius-lg ">
            <Navbar />

            <NavTab />

            {/* table paginations */}

            {/* toggle tab IP Proxy */}
            {ipProxyTabToggle && <IpProxyTab />}
            {/* toggle tab Socks5 */}
            {socks5TabToggle && <Socks5Tab />}
            {/* toggle tab Authentication */}
            {authTabToggle && <AuthenTab />}

            {/* table pagintaions */}
          </main>
        </React.Fragment>
      );
    }
  }
};

export default Dashboard;
