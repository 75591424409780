import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Sidebar1 from "../../../components/sidebar1/Sidebar1";
import Navbar from "../../../components/navbar/Navbar";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./packageManagement.css";

import {
  StyledDataGrid,
  CustomPagination,
} from "../../../components/styledDataGrid/StyledDataGrid";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { numberWithCommas } from "../../../util/util";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";

const PackageManagement = () => {
  // contexts
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  let [token, setToken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [showBuyPackageModal, setShowBuyPackageModal] = useState(false);
  const [packages, setPackages] = useState([]);
  const [resetPackages, setResetPackages] = useState(false);
  const [packageForSellInfo, setPackageForSellInfo] = useState([]);
  const [packageDetail, setPackageDetail] = useState([]);
  const [isShowedEditModal, setIsShowedEditlModal] = useState(false);
  const [packageProxyType, setPackageProxyType] = useState("");
  const [paymentInfo, setPaymentInfo] = useState({});

  const handleShowbuyPackageModal = () => {
    handleGetPackageInfo();
    setShowBuyPackageModal(true);
  };

  const handleCloseBuyPackageModal = () => {
    setShowBuyPackageModal(false);
    // set states
  };
  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("Text copied : " + text);
  };

  const handleCloseEditModal = () => {
    setIsShowedEditlModal(false);
  };

  const handleShowNotiConfirmed = () => {
    Swal.fire(
      "The order has been paid!",
      `Your package order has been paid. You can use it now`,
      "success"
    );
  };

  const handleShowTutorialBanking = async (params) => {
    const value = params.value;
    const id = params.row.id;
    await axios
      .get(`/api/v1/user-packages/${id}`)
      .then((res) => {
        if (res.status === 200) {
          // setPackageDetail(res.data.data);
          const cost = res?.data?.data?.final_price;
          Swal.fire({
            html: `
                  <h3 className="modal-title" id="exampleModalLabel">
                    Thanh toán đơn hàng
                  </h3>
                  <hr color="gray" width="100%" size="3px" align="center" />
                  <div class="css-onl3ck">
                    <div class="css-k3gz8i">
                      <div class="css-zd4of5">
                        <p class="chakra-text css-1a824c8">
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                          focusable="false" class="chakra-icon css-191mp66" height="1em" width="1em"
                          xmlns="http://www.w3.org/2000/svg">
                            <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                            </path>
                          </svg>
                          Đăng nhập vào
                          <strong>
                            Internet Banking
                          </strong>
                          ( ngân hàng điện tử )
                        </p>
                        <p class="chakra-text css-1ad58zi">
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                          focusable="false" class="chakra-icon css-191mp66" height="1em" width="1em"
                          xmlns="http://www.w3.org/2000/svg">
                            <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                            </path>
                          </svg>
                          Tạo giao dịch chuyển tiền tới tài khoản sau
                        </p>
                        <div style='background-color:#eee;padding:16px;text-align:left'>
                          <table>
                            <tr>
                              <td>Ngân hàng :</td>
                              <td>${paymentInfo.bank_name}</td>
                            </tr>
                            <tr>
                              <td>Tên chủ tài khoản :</td>
                              <td><strong>${paymentInfo.account_name}</strong></td>
                            </tr>
                            <tr>
                              <td>Số tài khoản :</td>
                              <td id='stk_banking' class='copyInfoBank'>
                                <strong>${paymentInfo.account_number}</strong>
                                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeXl2 css-1x60hrx" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ContentCopyIcon">
                                  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                                  </path>
                                </svg>
                              </td>
                            </tr>
                            <tr>
                              <td style='text-align:left'>Nội dung chuyển khoản :</td>
                              <td id='banking_code' class='copyInfoBank'>
                              <strong>${value}</strong>
                                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeXl2 css-1x60hrx" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ContentCopyIcon">
                                  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                                  </path>
                                </svg>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <p class="chakra-text css-1dzhzym" style='text-align:left'>
                          <strong>
                            ** Chú ý: Gói cước sẽ được nâng cấp sau khi khách hàng thanh toán thành công.
                          </strong>
                        </p>
                        <p class="chakra-text css-mj29dz" style='text-align:left'>
                          <strong>
                            ** Chuyển khoản thiếu không được nâng cấp, chuyển khoản thừa bị mất 10% phí xử lý
                          </strong>
                        </p>
                        <p class="chakra-text css-mj29dz" style='text-align:left'>
                          <strong>
                            ** Các giao dịch quá 15 ngày không báo kiểm tra sẽ không được xử lý
                          </strong>
                        </p>
                      </div>
                      <div class="css-zd4of5">
                        <p class="chakra-text css-1ad58zi">
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                          focusable="false" class="chakra-icon css-191mp66" height="1em" width="1em"
                          xmlns="http://www.w3.org/2000/svg">
                            <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                            </path>
                          </svg>
                          Hoặc quét
                          <strong>
                            Mã QR
                          </strong>
                        </p>
                        <div class="chakra-card css-1qhukjj">
                          <img src="https://img.vietqr.io/image/${paymentInfo.bank_code}-${paymentInfo.account_number}-compact2.png?amount=${cost}&addInfo=${value}&accountName=${paymentInfo.account_name}"
                          class="chakra-image css-ab50tw">
                        </div>
                      </div>
                    </div>
                  </div>
                  `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: {
              popup: "custom-popup", // Thêm lớp CSS tùy chỉnh cho thông báo
            },
            didOpen: () => {
              // Thêm sự kiện click cho từng phần tử có class "clickable-item"
              const clickableItems = document.querySelectorAll(".copyInfoBank");
              clickableItems.forEach((item) => {
                item.addEventListener("click", () => {
                  // Sao chép nội dung vào clipboard khi phần tử được click
                  copyToClipboard(item.innerText);
                });
              });
            },
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // set states
  };

  useEffect(() => {
    if (token) {
      axios
        .get(`/api/v1/user-packages`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setPackages(res.data.data.items);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [resetPackages]);

  useEffect(() => {
    axios
      .get(`/api/v1/users/company`, {})
      .then((res) => {
        if (res.status === 200) {
          setPaymentInfo(res?.data?.bank_info);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    packages.map((userPackage, index) => {
      userPackage.serial = index + 1;

      // thousand seperator to price
      userPackage.price = userPackage.price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      // thousand seperator to final price
      userPackage.final_price = userPackage.final_price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    });
  }, [packages]);

  const handleGetPackageInfo = () => {
    if (user?.referer) {
      axios
        .get(`/api/v1/user-packages/info?referer_id=${user.referer.id}`)
        .then((res) => {
          if (res.status === 200) {
            let sortedKeys = Object.keys(res.data.data).sort(
              (a, b) => res.data.data[a].sort - res.data.data[b].sort
            );
            let sortedData = sortedKeys.reduce((obj, key) => {
              obj[key] = res.data.data[key];
              return obj;
            }, {});

            setPackageForSellInfo(sortedData);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .get("/api/v1/user-packages/info")
        .then((res) => {
          if (res.status === 200) {
            let sortedKeys = Object.keys(res.data.data).sort(
              (a, b) => res.data.data[a].sort - res.data.data[b].sort
            );
            let sortedData = sortedKeys.reduce((obj, key) => {
              obj[key] = res.data.data[key];
              return obj;
            }, {});

            setPackageForSellInfo(sortedData);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const EditPackage = (packageID) => {
    console.log("=============================");
    console.log(packageID);
    console.log("=============================");
    setPackageProxyType("asd");
    setIsShowedEditlModal(true);
  };

  const PAGE_SIZE = 20;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const columns = useMemo(() => {
    const result = [
      // { field: "user_id", headerName: "USER ID", width: 300 },
      {
        field: "banking_code",
        headerName: "Banking Code",
        width: 200,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center">
              {params.row.confirmed === true && <span>{params.value}</span>}
              {params.row.confirmed === false && (
                <span className="text-danger">{params.value}</span>
              )}
            </div>
          );
        },
      },
      {
        field: "package_name",
        headerName: "Package Name",
        width: 130,
        renderCell: (params) => {
          return <span className="text-capitalize">{params.value}</span>;
        },
      },
      {
        field: "package_number",
        headerName: "Package Number",
        width: 160,
        renderCell: (params) => {
          return <span className="text-capitalize">{params.value}</span>;
        },
      },
      { field: "package_days", headerName: "Package Days", width: 120 },
      // { field: "proxy_number", headerName: "PROXY NUMBERS", width: 130 },
      {
        field: "proxy_type",
        headerName: "Type",
        width: 70,
        renderCell: (params) => {
          return (
            <div className="d-flex justify-content-between align-items-center w-100">
              <span>{params.value}</span>
            </div>
          );
        },
      },
      { field: "proxy_version", headerName: "Version", width: 90 },

      { field: "price", headerName: "Price (vnd)", width: 110 },
      { field: "discount", headerName: "Discount (%)", width: 130 },
      { field: "final_price", headerName: "Final Price (vnd)", width: 150 },
      { field: "started_at", headerName: "Start Package", width: 200 },
      { field: "expired_at", headerName: "End Package", width: 200 },
      {
        field: "confirmed",
        headerName: "Confirmed",
        width: 110,
        renderCell: (params) => {
          return (
            <div className="d-flex align-items-center">
              {params.value === true && (
                <span class="c-pill c-pill--success">True</span>
              )}
              {params.value === false && (
                <span class="c-pill c-pill--danger">False</span>
              )}
            </div>
          );
        },
      },
    ];
    return result;
  }, []);

  return (
    <React.Fragment>
      <Sidebar1 />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                {/* card header */}
                <div className="card-header text-uppercase pb-0">
                  <h5>Your Packages</h5>
                  <div className="border-top d-none"></div>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <div>
                      <span className="text-capitalize">Total:</span>
                      <span className="ms-2 text-bold">{packages.length}</span>
                    </div>

                    <Button
                      className="btn-info bg-gradient d-flex py-1 px-2"
                      onClick={handleShowbuyPackageModal}
                    >
                      <LocalMallIcon className="text-warning" />

                      <h6 class="text-sm text-white font-weight-normal mb-1 ms-2">
                        <span class="font-weight-bold text-center">
                          Buy Packages
                        </span>
                      </h6>
                    </Button>
                  </div>
                </div>

                {/* card body */}
                <div
                  className="card-body border-top px-0 pt-0 pb-2"
                  style={{ height: 690, width: "100%" }}
                >
                  <StyledDataGrid
                    //
                    sx={{
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                          display: "none",
                        },
                      "& .css-i4bv87-MuiSvgIcon-root": {
                        display: "none",
                      },
                    }}
                    rowHeight={45}
                    // test
                    // getRowHeight={() => "auto"}
                    rows={packages}
                    columns={columns}
                    // test
                    checkboxSelection
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}
                    slots={{
                      pagination: CustomPagination,
                    }}
                    onCellClick={(params) => {
                      if (params.field === columns[0].field) {
                        if (params.row.confirmed == false) {
                          // Kiểm tra nếu ô được bấm là cột đầu tiên
                          handleShowTutorialBanking(params);
                        } else {
                          handleShowNotiConfirmed();
                        }
                      }
                    }}
                    {...packages}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* MODALS */}
      {/* PACKAGE-CREATE MODAL */}
      <Modal
        dialogClassName="my-modal"
        show={showBuyPackageModal}
        onHide={handleCloseBuyPackageModal}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 10000 }}
        centered
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-center w-100">
            <Modal.Title className="text-uppercase">
              ROTATING RESIDENTIAL PROXY PACKAGES
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div class="demo mt-5 mb-5">
            <div class="container">
              <div class="row">
                {packageForSellInfo.length == 2 && (
                  <div class="col-md-2 col-sm-6"></div>
                )}
                {Object.keys(packageForSellInfo).map((key) => (
                  <div class="col-md-4 col-sm-6" key={key}>
                    <div
                      class={
                        packageForSellInfo[key]["best_seller"]
                          ? "pricingTable"
                          : "pricingTable green"
                      }
                    >
                      {packageForSellInfo[key]["best_seller"] && (
                        <div class="wrap">
                          <span class="ribbon6 text-danger fw-bold">
                            Best Seller
                          </span>
                        </div>
                      )}

                      <div class="pricingTable-header">
                        <i class="fa fa-adjust"></i>
                        <div class="price-value">
                          {numberWithCommas(
                            (packageForSellInfo[key]["price_per_month"] *
                              (100 - packageForSellInfo[key]["discount"])) /
                              100
                          )}
                          đ<span class="month fw-bolder">/1 month</span>
                        </div>
                        <span class="month">(~30 days)</span>
                      </div>
                      <h3 class="heading">{packageForSellInfo[key]["name"]}</h3>
                      <div class="pricing-content">
                        <ul>
                          <li>
                            Quantity:{" "}
                            <b>{packageForSellInfo[key]["total_proxy"]}</b>
                          </li>
                          <li>
                            Bandwidth: <b>Unlimited</b>
                          </li>
                          {packageForSellInfo[key]["discount"] != 0 && (
                            <>
                              <li>
                                Discount:{" "}
                                <b>{packageForSellInfo[key]["discount"]}%</b>
                              </li>
                              <li>
                                Original Price:{" "}
                                <b>
                                  {numberWithCommas(
                                    packageForSellInfo[key]["price_per_month"]
                                  )}
                                  đ
                                </b>
                              </li>
                            </>
                          )}

                          <li>
                            Proxy: <b>HTTP</b>, <b>SOCK5</b>
                          </li>
                        </ul>
                      </div>
                      <div class="pricingTable-signup">
                        <Link
                          to={`/proxy-products/order/package_number=1&proxy_type=http&package_name=${key}`}
                        >
                          Buy Package
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
                {packageForSellInfo.length == 2 && (
                  <div class="col-md-2 col-sm-6"></div>
                )}
              </div>
            </div>
          </div>

          {/* test */}
          {/* <div class="ribbon"> */}
          {/* <div class="wrap">
            <span class="ribbon6 text-bold text-danger">Giá siêu tốt</span>
          </div> */}
          {/* </div> */}
          {/* test */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBuyPackageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Proxy Detail and Update modal*/}
      <Modal
        show={isShowedEditModal}
        onHide={handleCloseEditModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.FloatingLabel label="AUTHENTICATION OPTIONS">
                <Form.Control
                  disabled
                  type="text"
                  value={packageProxyType}
                  autoFocus
                />
              </Form.FloatingLabel>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          {/* <Button
            variant="success"
            onClick={() =>
              handleUpdateProxy(
                urlUpdate,
                portsUpdate,
                statusUpdate,
                liveUpdate,
                proxyTypeUpdate,
                proxyVersionUpdate,
                packageNameUpdate,
                proxyObject.id
              )
            }
          >
            Update
          </Button> */}
        </Modal.Footer>
      </Modal>
      {/* END OF PACKAGE-CREATE MODAL */}
      {/* END OF MODALS */}
    </React.Fragment>
  );
};

export default PackageManagement;
