import React, { useState, useEffect } from "react";
import { useContext } from "react";
import axios from "axios";
import AuthContext from "../../contexts/AuthContext";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { Form } from "react-bootstrap";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";

const AuthenTab = ({
  packageId,
  proxyType,
  proxyConfig,
  setUpdateDataFlag,
  updateDataFlag,
}) => {
  const [whiteListIpTabChecked, setWhiteListIpChecked] = useState(true);
  const [usernamePasswordTabChecked, setUsernamePasswordTabChecked] =
    useState(false);

  const [ipInput, setIpInput] = useState("");

  const [flag, setFlag] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [ipWhiteList, setIpWhiteList] = useState([]);
  const [authProxyUsername, setAuthProxyUsername] = useState("");
  const [authProxyPassword, setAuthProxyPassword] = useState("");
  const [rotationTime, setRotationTime] = useState(0);
  const [show, setShow] = useState(false);
  const [whiteListIpSaveFlag, setWhiteListIpSaveFlag] = useState(false);
  const [userPasswordSaveFlag, setUserPasswordSaveFlag] = useState(false);
  const [hideUserPassword, setHideUserPassword] = useState(true);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleAuthProxyUserChange = (proxyUsername) => {
    setAuthProxyUsername(proxyUsername);
    setUserPasswordSaveFlag(true);
  };

  const handleAuthProxyPasswordChange = (proxyPassword) => {
    setAuthProxyPassword(proxyPassword);
    setUserPasswordSaveFlag(true);
  };

  let handleChangeRotationTime = async (rotate_time) => {
    if (rotate_time < 10) {
      setRotationTime(proxyConfig?.rotate);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your rotate time input must be greater than 10!",
      });
    }

    if (rotate_time % 10 != 0) {
      setRotationTime(proxyConfig?.rotate);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your rotate time input must be multiple of 10!",
      });
    }

    if (rotate_time >= 5000) {
      setRotationTime(proxyConfig?.rotate);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your rotate time input has exceeded the maximum, please re-enter!",
      });
    }

    let data = {
      auth_user: authProxyUsername + "|" + authProxyPassword,
      auth_ip: ipWhiteList.join(","),
      rotate: Number(rotationTime),
    };

    if (typeof data.rotate === "number") {
      let token = localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null;

      const headers = { Authorization: "Bearer " + token };

      axios
        .post("/api/v1/user-proxies/" + packageId + "/config-proxy", data, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            Swal.fire(
              "Updated!",
              `Config rotation time successfully!`,
              "success"
            );
            // setFlag((current) => !current);
            // setRotationTime(data["rotate"]);
            setUpdateDataFlag(true); //call to parent to update data table
          } else {
            // setRotationTime(proxyConfig?.)
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Message: " + `${error?.response?.data?.message}`,
          });
        });
    }

    setShow(false);
  };

  const removeFromWhiteList = (ipToRemove) => {
    setIpWhiteList((prevList) => prevList.filter((ip) => ip !== ipToRemove));
    setWhiteListIpSaveFlag(true);
  };

  // ValidateIPaddress
  const ValidateIPaddress = (ipaddress) => {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  // end of ValidateIPaddress

  // contexts
  const authContext = useContext(AuthContext);
  const { user, getProfile } = authContext;

  useEffect(() => {
    let ips = proxyConfig?.auth_ip.split(",");
    let credentials = proxyConfig?.auth_user.split("|");
    let rotate = proxyConfig?.rotate;

    if (ips[0] == "") {
      setIpWhiteList([]);
    } else {
      setIpWhiteList(ips);
    }

    setAuthProxyUsername(credentials[0]);
    if (credentials[1] != "undefined") {
      setAuthProxyPassword(credentials[1]);
    } else {
      setAuthProxyPassword("");
    }

    setRotationTime(rotate);
  }, []);

  useEffect(() => {
    console.log("====================UPDATE DATA==================");
    console.log("authProxyUsername", authProxyUsername);
    console.log("authProxyPass", authProxyPassword);
    console.log("====================UPDATE DATA==================");
  }, [authProxyUsername, authProxyPassword]);

  useEffect(() => {
    if (proxyType == "sock") {
      setWhiteListIpChecked(true);
      setUsernamePasswordTabChecked(false);
    } else if (proxyType == "http") {
      //otherwise is "http"
      if (user.auth_user == true) {
        setHideUserPassword(false);
        if (proxyConfig.auth_ip.length > 0) {
          setWhiteListIpChecked(true);
          setUsernamePasswordTabChecked(false);
        } else {
          setWhiteListIpChecked(false);
          setUsernamePasswordTabChecked(true);
        }
      } else {
        setHideUserPassword(true);
        setUsernamePasswordTabChecked(false);
        setWhiteListIpChecked(true);
      }
    }
  }, []);

  const addIpToTempWhiteList = (e, ipItem) => {
    e.preventDefault();

    if (ipItem === "") {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your input ip is empty!",
      });
    }

    // if duplicate input ip return swal error
    if (ipWhiteList.includes(ipItem)) {
      setIpInput("");
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You have entered a duplicate input IP!",
      });
    }

    if (ValidateIPaddress(ipItem)) {
      if (ipWhiteList.length < 3) {
        setIpWhiteList((prev) => [...prev, ipItem]);
        setWhiteListIpSaveFlag(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You have exceeded the maximum number of input IPs!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You have entered an invalid input IP!",
      });
    }
  };

  const SaveIpWhiteListChanges = (e) => {
    e.preventDefault();
    let data = {
      auth_ip: ipWhiteList.join(","),
      auth_user: "",
      rotate: rotationTime,
    };

    let token = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;

    const headers = { Authorization: "Bearer " + token };

    axios
      .post("/api/v1/user-proxies/" + packageId + "/config-proxy", data, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire("Updated!", `Config IP Whitelist successfully!`, "success");
          setAuthProxyUsername("");
          setAuthProxyPassword("");
          setIpInput("");
          setWhiteListIpSaveFlag(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Message: " + `${error?.response?.data?.message}`,
        });
      });
    setShow(false);
    setUpdateDataFlag(true); //call to parent to update data table
  };

  const EditAuthProxyCredentials = (e, auth_proxy_user, auth_proxy_pass) => {
    e.preventDefault();

    if ((auth_proxy_user?.length == 0) | (auth_proxy_user == undefined)) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Message: " + `Auth proxy username input can not be empty`,
      });
    }

    if ((auth_proxy_pass?.length == 0) | (auth_proxy_pass == undefined)) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Message: " + `Auth proxy password input can not be empty`,
      });
    }

    let data = {
      auth_user: auth_proxy_user + "|" + auth_proxy_pass,
      auth_ip: "",
      rotate: rotationTime,
    };

    let token = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;

    const headers = { Authorization: "Bearer " + token };
    axios
      .post("/api/v1/user-proxies/" + packageId + "/config-proxy", data, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire(
            "Edited!",
            `The authentication proxy credentials have been edited!`,
            "success"
          );
          setIpWhiteList([]);
          setUserPasswordSaveFlag(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setShow(false);
    setUpdateDataFlag(true); //call to parent to update data table
  };

  return (
    <>
      {/* Edit Setting */}
      <div className="row mt-4 px-2">
        {proxyType == "http" ? (
          <div className="card-header pb-0 d-flex justify-content-between align-items-center">
            <Alert severity="warning" className="w-100 text-gray">
              YOU ARE ONLY ALLOWED TO USE ONE OF THE FOLLOWING TWO OPTIONS.
            </Alert>
            <button
              type="button"
              // className={
              //   isDisabledConfigButton
              //     ? "text-white btn bg-primary bg-gradient ms-2 d-none"
              //     : "text-white btn bg-primary bg-gradient ms-2"
              // }
              className="text-white btn bg-primary bg-gradient ms-2 my-0"
              onClick={handleShow}
              style={{ height: "40px" }}
            >
              <SettingsRoundedIcon className="text-md" />
            </button>
          </div>
        ) : (
          <div className="card-header pb-0 d-flex justify-content-end align-items-center">
            <button
              type="button"
              // className={
              //   isDisabledConfigButton
              //     ? "text-white btn bg-primary bg-gradient ms-2 d-none"
              //     : "text-white btn bg-primary bg-gradient ms-2"
              // }
              className="text-white btn bg-primary bg-gradient ms-2 my-0"
              onClick={handleShow}
              style={{ height: "40px" }}
            >
              <SettingsRoundedIcon className="text-md" />
            </button>
          </div>
        )}
      </div>

      {/* Check Form Row */}
      <div className="row mt-2 px-4">
        {/* Whitelist IP FORM */}
        <div
          // className={proxyType == "sock" ? "col-12" : "col-6"}
          className={
            hideUserPassword | (proxyType == "sock") ? "col-12" : "col-6"
          }
        >
          <div className="text-start card mb-4">
            <div className="card-header pb-0">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={whiteListIpTabChecked}
                  onChange={() => {
                    setWhiteListIpChecked(true);
                    setUsernamePasswordTabChecked(false);
                  }}
                />

                <label className="form-check-label" for="flexRadioDefault1">
                  <h6>Whitelist IP</h6>
                </label>
              </div>
            </div>

            {/* <div className="card-body px-0 pt-0 pb-2"></div> */}
          </div>
        </div>

        {/* Username & password checkbox */}
        <div
          className={hideUserPassword ? "d-none" : "col-6"}
          // className={proxyType == "http" ? "col-6" : "col-12 d-none"}
        >
          <div className="text-start card mb-4">
            <div className="card-header pb-0">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="userpassradiocheckbox"
                  checked={usernamePasswordTabChecked}
                  onChange={() => {
                    setWhiteListIpChecked(false);
                    setUsernamePasswordTabChecked(true);
                  }}
                />

                <label className="form-check-label" for="userpassradiocheckbox">
                  <h6>Username & Password</h6>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Input Form */}
      <div className="row px-4">
        {/* Whitelist IP FORM */}
        <div
          className={
            hideUserPassword | (proxyType == "sock") ? "col-12" : "col-6"
          }
          // className={proxyType == "sock" ? "col-12" : "col-6"}
        >
          <div className="text-start card mb-4">
            <div className="card-body px-3 py-2">
              {/* form */}
              <form>
                <fieldset disabled={whiteListIpTabChecked ? false : true}>
                  <h6 className="text-center py-2">
                    IP whitelist ( Maximum 3 )
                  </h6>

                  <div className="mb-1 border-top">
                    <label for="exampleInputText1" className="form-label mt-3">
                      <span style={{ fontSize: "14px" }}>Input IP</span>
                    </label>

                    <div className="d-flex align-items-center justify-content-center">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputText1"
                        aria-describedby="textHelp"
                        disabled={ipWhiteList.length === 3 ? true : false}
                        value={ipInput}
                        onChange={(e) => setIpInput(e.target.value)}
                        placeholder={
                          ipWhiteList.length === 3 &&
                          "You have reached the maximum, remove some to add new ones!"
                        }
                      />

                      <button
                        className="btn btn-success ms-2 my-0"
                        onClick={(e) => addIpToTempWhiteList(e, ipInput)}
                      >
                        Add
                      </button>
                    </div>

                    <div id="emailHelp" className="form-text">
                      Enter Input IP here.
                    </div>
                  </div>
                  <div>
                    <ul className="list-group list-group-flush border-top">
                      <div className="row">
                        <div className="col-6">
                          <h6 className="text-start py-2">IP whitelist:</h6>
                        </div>
                        <div className="col-6">
                          <h6 className="text-start py-2">Action:</h6>
                        </div>
                      </div>

                      {ipWhiteList.length === 0 && (
                        <div className="row">
                          <span className="text-info fs-6">
                            The list is currently empty...
                          </span>
                        </div>
                      )}

                      {/* className="list-group-item" */}
                      {ipWhiteList &&
                        ipWhiteList.map((item, index) => (
                          <li
                            key={index}
                            className={
                              whiteListIpTabChecked
                                ? "list-group-item"
                                : "list-group-item disabled"
                            }
                          >
                            <div className="row">
                              <div className="col-6 d-flex align-items-center">
                                <span className="text-success fw-bold">
                                  {item}
                                </span>
                              </div>
                              <div className="col-6 d-flex align-items-center">
                                {deleteLoading ? (
                                  <CircularProgress size="2rem" color="error" />
                                ) : (
                                  <button
                                    type="button"
                                    // onClick={() => editIpWhiteList(item)}
                                    onClick={() => removeFromWhiteList(item)}
                                    className={
                                      whiteListIpTabChecked
                                        ? "btn btn-sm btn-outline-danger"
                                        : "b  tn btn-sm btn-outline-secondary"
                                    }
                                  >
                                    remove
                                  </button>
                                )}
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="d-flex justify-content-center">
                    {whiteListIpSaveFlag && (
                      <button
                        className="btn btn-success w-100"
                        // onClick={(e) => AddIpItemIntoIpWhiteList(e, ipInput)}
                        onClick={(e) => SaveIpWhiteListChanges(e)}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </fieldset>
              </form>
              {/* end of form */}
              {/* test */}

              {/* test */}
              {/* ip whitelist */}
            </div>
          </div>
        </div>

        {/* Username & password FORM */}
        <div
          className={hideUserPassword ? "d-none" : "col-6"}
          // className={proxyType == "http" ? "col-6" : "col-12 d-none"}
        >
          <div className="text-start card mb-4">
            <div className="card-body px-3 py-2">
              <form>
                <fieldset disabled={usernamePasswordTabChecked ? false : true}>
                  <h6 className="text-center py-2">Username & Password</h6>

                  <div className="mb-1 border-top">
                    <label for="usernameTextInput" className="form-label mt-3">
                      <span style={{ fontSize: "14px" }}>Username:</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="usernameTextInput"
                      aria-describedby="emailHelp"
                      placeholder="Enter Username..."
                      value={authProxyUsername}
                      checked={usernamePasswordTabChecked}
                      onChange={(e) =>
                        handleAuthProxyUserChange(e.target.value)
                      }
                    />

                    <label for="usernameTextInput" className="form-label mt-3">
                      <span style={{ fontSize: "14px" }}>Password:</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="usernameTextInput"
                      aria-describedby="emailHelp"
                      placeholder="Enter Password..."
                      value={authProxyPassword}
                      onChange={(e) =>
                        handleAuthProxyPasswordChange(e.target.value)
                      }
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    {userPasswordSaveFlag && (
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) =>
                          EditAuthProxyCredentials(
                            e,
                            authProxyUsername,
                            authProxyPassword
                          )
                        }
                      >
                        Save
                      </button>
                    )}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <Modal
        style={{ zIndex: 99999 }}
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change rotation time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Time Rotate ( Minutes )</Form.Label>
              <Form.Control
                type="number"
                value={rotationTime}
                onChange={(e) => setRotationTime(e.target.value)}
                placeholder="Example: 10, 20"
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleChangeRotationTime(rotationTime)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// <CircularProgress size="2rem" />

export default AuthenTab;
