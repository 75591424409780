import React, { useEffect, useState } from "react";
import logo1 from "../../assets/img/proxy-10.png"; //boot24hproxy
import logo2 from "../../assets/img/mkproxy-logo-9.png"; //mkproxy
import { Link, Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

const Login = () => {
  const authContext = useContext(AuthContext);

  const { loginUser, user, errors, setErrors, domain } = authContext;
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is not valid";
    }

    if (!formData.password.trim()) {
      validationErrors.password = "Password is required";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      loginUser(formData.email, formData.password);
    }
  };
  // If user role is user then redirect to dashboard route
  // If user role is admin then redirect to admin route
  if (user) {
    if (user?.group?.role === "user") {
      return <Navigate to="/user/proxy-management" />;
    } else if (
      user?.group?.role === "admin" ||
      user?.group?.role === "superadmin"
    ) {
      return <Navigate to="/admin/proxy-management" />;
    } else if (user?.group?.role === "reseller") {
      return <Navigate to="/reseller/user-management" />;
    }
  } else {
    return (
      <React.Fragment>
        <main class="main-content  mt-0">
          <section>
            <div class="page-header min-vh-100">
              <div class="container">
                <div class="row">
                  <div class="col-xl-5 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                    <div className="text-center">
                      {domain == "boost24h" ? (
                        <img src={logo1} className="w-100" />
                      ) : (
                        <img src={logo2} className="w-80" />
                      )}
                    </div>
                    <div class="card card-plain">
                      <div class="card-header pb-0 text-start">
                        <h4 class="font-weight-bolder">Login</h4>
                        <p class="mb-0">
                          Enter your email and password to login
                        </p>
                      </div>
                      <div class="card-body">
                        <form
                          role="form"
                          novalidate="novalidate"
                          onSubmit={handleSubmit}
                        >
                          <div class="mb-3">
                            <input
                              class="form-control form-control-lg"
                              type="email"
                              name="email"
                              placeholder="Email"
                              autoComplete="off"
                              // value={form.email}
                              onChange={handleChange}
                              aria-label="Email"
                            />
                            {errors.email && (
                              <span
                                className="text-danger ms-2"
                                style={{ fontSize: "0.8rem" }}
                              >
                                {errors.email}
                              </span>
                            )}
                          </div>
                          <div class="mb-3">
                            <input
                              type="password"
                              class="form-control form-control-lg"
                              placeholder="******"
                              // value={form.password}
                              onChange={handleChange}
                              aria-label="Password"
                              name="password"
                            />
                            {errors.password && (
                              <span
                                className="text-danger ms-2"
                                style={{ fontSize: "0.9rem" }}
                              >
                                {errors.password}
                              </span>
                            )}
                          </div>

                          {errors?.submit && (
                            <span
                              style={{ fontSize: "0.9rem" }}
                              className="text-danger"
                            >
                              {errors.submit}
                            </span>
                          )}

                          <div class="form-check form-switch d-flex justify-content-between">
                            <div>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="rememberMe"
                              />
                              <label class="form-check-label" for="rememberMe">
                                Remember me
                              </label>
                            </div>
                            <div>
                              <Link to="/login" class="form-check-label" c>
                                Forgot password?
                              </Link>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              type="submit"
                              class="btn btn-lg btn-info btn-lg w-100 mt-4 mb-0"
                            >
                              Login
                            </button>
                          </div>
                          <div class="mt-1">
                            <label class="form-check-label fs-6">
                              No account yet?
                            </label>{" "}
                            <Link
                              to="/register"
                              className="form-check-label text-primary fs-6"
                            >
                              Create a new one
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                    <div
                      class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                      style={{
                        backgroundImage:
                          // "url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg')",
                          // "url('https://techrab.com/wp-content/uploads/2022/02/Proxy-Server-PS4.gif')",
                          "url('../assets/img/proxy-backgound.gif')",
                        backgroundSize: "cover",
                      }}
                    >
                      {/* <span class="mask bg-gradient-info opacity-6"></span> */}
                      {/* <h4 class="mt-5 text-white font-weight-bolder position-relative">
                        "Attention is the new currency"
                      </h4>
                      <p class="text-white position-relative">
                        The more effortless the writing looks, the more effort the
                        writer actually put into the process.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
};

export default Login;
